/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-list {
  padding: 24px; }
  .product-list__inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto; }
  .product-list__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 260px);
    grid-gap: 24px; }
