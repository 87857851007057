/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.new-item {
  width: 100%;
  border: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  position: relative; }
  .new-item:hover {
    cursor: pointer; }
  .new-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .new-item__add-icon {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; }
    .new-item__add-icon:hover svg {
      transform: scale(1.4); }
    .new-item__add-icon svg {
      transition: transform 0.3s ease-out;
      backface-visibility: hidden;
      width: 40px;
      height: 40px; }
      .new-item__add-icon svg path {
        fill: #000; }
