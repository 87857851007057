/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.page-actions {
  padding: 24px; }
  .page-actions__inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto; }
  .page-actions__actions {
    margin-left: auto; }
  .page-actions__image {
    height: 64px;
    width: 64px;
    margin-right: 16px; }
    .page-actions__image * {
      width: 100%;
      height: 100%; }
    .page-actions__image .user-avatar__image {
      width: 100%;
      height: 100%; }
  .page-actions__inner {
    display: flex;
    margin: 0 auto;
    align-items: center; }
  .page-actions__sub-title {
    font-size: 14px;
    line-height: 23px;
    color: #4d4d4d; }
  .page-actions__title {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 0; }
  .page-actions__titles {
    display: flex;
    flex-direction: column; }
  .page-actions--stick-on-scroll {
    position: sticky;
    top: 0;
    background-color: #FAFAFA;
    z-index: 9; }
