/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.price-field {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .price-field__container {
    display: flex; }
  .price-field__dollar-sign {
    width: 46px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px #E0E0E0;
    border-right: none; }
  .price-field__label {
    margin-bottom: 8px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    display: block; }
  .price-field .rmd-text-field-container {
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
