/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.form-file {
  margin-top: 8px;
  padding-bottom: 8px;
  position: relative; }
  .form-file__container {
    position: relative;
    width: 200px;
    height: 200px;
    border: solid 1px  #E0E0E0;
    border-radius: 3px; }
    .form-file__container--creating .form-file__cta {
      transform: translate(-50%, -50%);
      opacity: 1; }
    .form-file__container:before {
      transition: opacity 0.3s ease-out;
      backface-visibility: hidden;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.6);
      opacity: 0; }
    .form-file__container:hover:before {
      opacity: 1; }
    .form-file__container:hover .form-file__cta {
      transform: translate(-50%, -50%);
      opacity: 1; }
  .form-file__cta {
    transition: all 0.3s ease-out;
    backface-visibility: hidden;
    width: 60%;
    padding: 8px 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    text-align: center;
    z-index: 2;
    border: solid 2px #333;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    opacity: 0; }
  .form-file__image {
    width: 100%;
    height: 100%; }
    .form-file__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .form-file__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
    opacity: 0; }
  .form-file__label {
    margin-bottom: 8px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    display: block; }
