/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.single-product-page__content {
  padding: 24px; }
  .single-product-page__content__inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto; }

.single-product-page .page-actions {
  margin-top: -24px;
  margin-left: -24px;
  width: calc(100% + 48px); }
