/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-image-form {
  position: relative; }
  .product-image-form__drop {
    transition: opacity 0.3s ease-out;
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    border: dashed 4px rgba(51, 51, 51, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 3;
    pointer-events: none;
    height: 50%;
    top: 44%;
    opacity: 0; }
    .product-image-form__drop--active {
      opacity: 0; }
  .product-image-form__example {
    display: flex;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #B8B8B8; }
    .product-image-form__example__description {
      max-width: 250px;
      font-size: 14px;
      line-height: 17px; }
      .product-image-form__example__description a {
        color: #FC5858;
        text-decoration: none; }
        .product-image-form__example__description a:hover {
          text-decoration: underline; }
    .product-image-form__example__image {
      margin-right: 16px; }
  .product-image-form__images {
    position: relative; }
    .product-image-form__images__actions {
      margin-top: 24px; }
    .product-image-form__images__grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(100px, 1fr));
      grid-gap: 24px; }
      .product-image-form__images__grid__item {
        width: 100%;
        height: 250px;
        position: relative; }
        .product-image-form__images__grid__item:hover img {
          opacity: 0.3; }
        .product-image-form__images__grid__item:hover .product-image-form__images__grid__item__delete {
          opacity: 1; }
        .product-image-form__images__grid__item__delete {
          transition: .5s ease;
          opacity: 0;
          position: absolute;
          top: 10px;
          right: 10px; }
          .product-image-form__images__grid__item__delete svg {
            position: absolute;
            right: 0;
            transition: .5s ease;
            cursor: pointer;
            z-index: 5; }
        .product-image-form__images__grid__item img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: .5s ease;
          backface-visibility: hidden;
          opacity: 1;
          transition: .5s ease;
          backface-visibility: hidden; }
      .product-image-form__images__grid .new-item {
        border: 2px dashed #d5d5d5; }
      @media all and (max-width: 768px) {
        .product-image-form__images__grid {
          grid-template-columns: minmax(100px, 1fr); } }
