@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap);
.sidebar-user-profile {
  width: 100%;
  display: flex; }
  .sidebar-user-profile__menu {
    margin-left: auto; }
    .sidebar-user-profile__menu svg path {
      fill: #6e82a5; }

.user-avatar-with-name {
  display: flex;
  align-items: center;
  height: 100%; }
  .user-avatar-with-name__name {
    color: #6e82a5;
    font-family: Inter;
    font-weight: 600; }

.user-avatar__image {
  width: 40px;
  height: 40px;
  background: #eee;
  border: solid 2px #6e82a5;
  padding: 4px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px; }

.user-avatar .rmd-avatar {
  margin-right: 8px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.sidebar {
  background-color: #101924;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  .sidebar__item {
    transition: color 0.3s ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 40px;
    padding: 0 24px;
    color: #6e82a5;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-weight: 700; }
    .sidebar__item--align-center {
      display: flex;
      align-items: center;
      line-height: 24px; }
    .sidebar__item span {
      -webkit-transform: translateY(2px);
              transform: translateY(2px); }
    .sidebar__item svg {
      margin-right: 8px; }
      .sidebar__item svg path {
        transition: fill 0.3s ease-out;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        fill: #6e82a5; }
    .sidebar__item--active, .sidebar__item:hover {
      color: #fff; }
      .sidebar__item--active svg path, .sidebar__item:hover svg path {
        fill: #fff; }
  .sidebar__list__children {
    transition: max-height 0.3s ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    padding-left: 32px;
    max-height: 0;
    overflow: hidden; }
  .sidebar__list--has-children {
    position: relative; }
    .sidebar__list--has-children:before {
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      content: '';
      position: absolute;
      top: 8px;
      right: 24px;
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' class='rmd-icon rmd-icon--svg' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%236e82a5' d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E");
      width: 24px;
      height: 24px;
      background-size: contain; }
    .sidebar__list--has-children.sidebar__list--active:before {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' class='rmd-icon rmd-icon--svg' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E"); }
  .sidebar__list--active .sidebar__list__children {
    max-height: 300px; }
  .sidebar__logo {
    width: 100%;
    height: 85px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    text-align: center;
    padding: 16px; }
    .sidebar__logo img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 200px; }
      .sidebar__logo img.left-align {
        width: auto; }
      .sidebar__logo img.color {
        background-color: white; }
  .sidebar__nav {
    margin-top: 16px;
    flex: 1 1; }
  .sidebar__user-info {
    border-top: solid 1px rgba(255, 255, 255, 0.3);
    height: 85px;
    padding: 16px;
    display: flex;
    align-items: center; }
    .sidebar__user-info .user-avatar__name {
      color: #6e82a5; }
  .sidebar__user-profile {
    display: flex;
    align-items: center; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.new-item {
  width: 100%;
  border: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  position: relative; }
  .new-item:hover {
    cursor: pointer; }
  .new-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .new-item__add-icon {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; }
    .new-item__add-icon:hover svg {
      -webkit-transform: scale(1.4);
              transform: scale(1.4); }
    .new-item__add-icon svg {
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      width: 40px;
      height: 40px; }
      .new-item__add-icon svg path {
        fill: #000; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.page-actions {
  padding: 24px; }
  .page-actions__inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto; }
  .page-actions__actions {
    margin-left: auto; }
  .page-actions__image {
    height: 64px;
    width: 64px;
    margin-right: 16px; }
    .page-actions__image * {
      width: 100%;
      height: 100%; }
    .page-actions__image .user-avatar__image {
      width: 100%;
      height: 100%; }
  .page-actions__inner {
    display: flex;
    margin: 0 auto;
    align-items: center; }
  .page-actions__sub-title {
    font-size: 14px;
    line-height: 23px;
    color: #4d4d4d; }
  .page-actions__title {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 0; }
  .page-actions__titles {
    display: flex;
    flex-direction: column; }
  .page-actions--stick-on-scroll {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FAFAFA;
    z-index: 9; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.paper__title {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #999;
  line-height: 1; }

.paper__paper {
  background-color: #fff;
  padding: 24px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.loader {
  transition: opacity 0.3s ease-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  pointer-events: none; }
  .loader__visible {
    opacity: 1;
    pointer-events: auto; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-list-item {
  padding: 16px 16px 0;
  max-width: 260px;
  background-color: #fff;
  text-decoration: none; }
  .product-list-item__actions {
    margin-bottom: 16px; }
  .product-list-item__active-pill {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.289412px;
    text-transform: uppercase;
    padding: 4px 16px;
    border-radius: 25px;
    background-color: #BEECAE;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #000; }
    .product-list-item__active-pill--inactive {
      opacity: 0.5; }
  .product-list-item--add-new .product-list-item__title {
    text-align: center; }
  .product-list-item__image {
    margin-bottom: 16px;
    position: relative;
    max-height: 205px;
    overflow: hidden; }
    .product-list-item__image img {
      width: 100%;
      height: 100%;
      max-height: 205px;
      object-fit: cover;
      object-position: center; }
  .product-list-item__price {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.289412px;
    color: #FC5858;
    display: flex; }
    .product-list-item__price__regular--on-sale {
      margin-left: 8px;
      color: #979797;
      text-decoration: line-through; }
  .product-list-item__title {
    font-size: 16px;
    line-height: 19px;
    color: #141414;
    margin-bottom: 16px;
    font-weight: 400; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-list {
  padding: 24px; }
  .product-list__inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto; }
  .product-list__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 260px);
    grid-gap: 24px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.attribute-selector__label {
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  display: block; }

.attribute-selector__results {
  margin-bottom: 16px; }
  .attribute-selector__results > button {
    margin-right: 16px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.description-form {
  font-size: 14px;
  line-height: 17px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.form-file {
  margin-top: 8px;
  padding-bottom: 8px;
  position: relative; }
  .form-file__container {
    position: relative;
    width: 200px;
    height: 200px;
    border: solid 1px  #E0E0E0;
    border-radius: 3px; }
    .form-file__container--creating .form-file__cta {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      opacity: 1; }
    .form-file__container:before {
      transition: opacity 0.3s ease-out;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.6);
      opacity: 0; }
    .form-file__container:hover:before {
      opacity: 1; }
    .form-file__container:hover .form-file__cta {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      opacity: 1; }
  .form-file__cta {
    transition: all 0.3s ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    width: 60%;
    padding: 8px 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -35%);
            transform: translate(-50%, -35%);
    text-align: center;
    z-index: 2;
    border: solid 2px #333;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    opacity: 0; }
  .form-file__image {
    width: 100%;
    height: 100%; }
    .form-file__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .form-file__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
    opacity: 0; }
  .form-file__label {
    margin-bottom: 8px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    display: block; }

.form-header {
  padding-top: 40px;
  padding-bottom: 24px;
  background-color: #F4F4F4;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-weight: 700;
  margin-bottom: 8px;
  width: calc(100% + 48px);
  -webkit-transform: translateX(-24px);
          transform: translateX(-24px);
  margin-top: 16px; }
  .form-header--top-offset {
    padding-top: 24px;
    -webkit-transform: translate(-24px, -24px);
            transform: translate(-24px, -24px);
    margin-top: 0;
    margin-bottom: 0; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.price-field {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .price-field__container {
    display: flex; }
  .price-field__dollar-sign {
    width: 46px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px #E0E0E0;
    border-right: none; }
  .price-field__label {
    margin-bottom: 8px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    display: block; }
  .price-field .rmd-text-field-container {
    flex: 1 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-image-form {
  position: relative; }
  .product-image-form__drop {
    transition: opacity 0.3s ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    border: dashed 4px rgba(51, 51, 51, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 3;
    pointer-events: none;
    height: 50%;
    top: 44%;
    opacity: 0; }
    .product-image-form__drop--active {
      opacity: 0; }
  .product-image-form__example {
    display: flex;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #B8B8B8; }
    .product-image-form__example__description {
      max-width: 250px;
      font-size: 14px;
      line-height: 17px; }
      .product-image-form__example__description a {
        color: #FC5858;
        text-decoration: none; }
        .product-image-form__example__description a:hover {
          text-decoration: underline; }
    .product-image-form__example__image {
      margin-right: 16px; }
  .product-image-form__images {
    position: relative; }
    .product-image-form__images__actions {
      margin-top: 24px; }
    .product-image-form__images__grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(100px, 1fr));
      grid-gap: 24px; }
      .product-image-form__images__grid__item {
        width: 100%;
        height: 250px;
        position: relative; }
        .product-image-form__images__grid__item:hover img {
          opacity: 0.3; }
        .product-image-form__images__grid__item:hover .product-image-form__images__grid__item__delete {
          opacity: 1; }
        .product-image-form__images__grid__item__delete {
          transition: .5s ease;
          opacity: 0;
          position: absolute;
          top: 10px;
          right: 10px; }
          .product-image-form__images__grid__item__delete svg {
            position: absolute;
            right: 0;
            transition: .5s ease;
            cursor: pointer;
            z-index: 5; }
        .product-image-form__images__grid__item img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: .5s ease;
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          opacity: 1;
          transition: .5s ease;
          backface-visibility: hidden; }
      .product-image-form__images__grid .new-item {
        border: 2px dashed #d5d5d5; }
      @media all and (max-width: 768px) {
        .product-image-form__images__grid {
          grid-template-columns: minmax(100px, 1fr); } }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-variant__divider {
  height: 1px;
  background-color: #E0E0E0;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%; }

.product-variant__item {
  margin-bottom: 24px; }

.product-variant .amped-form {
  display: flex;
  align-items: flex-end; }
  .product-variant .amped-form .amped-form__grid {
    flex: 1 1;
    margin-right: 16px; }

.product-variant .form-builder__actions .rmd-button {
  height: 48px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.select-form-field__label {
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  display: block; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.switch-form .rmd-switch__input:checked + label:after {
  background-color: #B92754 !important; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.rp-textarea__label {
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  display: block; }

.rp-textarea .rdw-editor-toolbar {
  border-color: #E0E0E0; }

.rp-textarea .rdw-editor-main {
  border: solid 1px #E0E0E0;
  margin: 0;
  padding: 0 16px;
  min-height: 250px; }

.rp-textarea .rdw-option-wrapper {
  transition: background-color 0.3s ease-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border: none !important;
  box-shadow: none;
  height: 25px; }
  .rp-textarea .rdw-option-wrapper:hover {
    background-color: #eee; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.text-form-field__label {
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  display: block; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.amped-graph-query__inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.single-product-page__content {
  padding: 24px; }
  .single-product-page__content__inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto; }

.single-product-page .page-actions {
  margin-top: -24px;
  margin-left: -24px;
  width: calc(100% + 48px); }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.user-profile-page .amped-graph-query__inner {
  margin-top: 24px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.auth {
  width: 480px;
  background: #fff;
  padding: 40px; }
  .auth--register {
    width: 655px; }
  .auth__actions {
    margin-top: 16px;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end; }
    .auth__actions button {
      width: 100%; }
  .auth__auth-urls {
    display: flex;
    justify-content: center; }
  .auth__description {
    margin-bottom: 32px; }
  .auth__error {
    margin-bottom: 16px;
    color: #cc0000; }
  .auth__field {
    margin-bottom: 16px; }
  .auth__form__content p {
    font-size: 14px;
    line-height: 23px; }
  .auth__form__logo {
    width: 50%;
    margin-bottom: 24px; }
    .auth__form__logo img {
      width: 100%; }
  .auth__or {
    width: 200px;
    position: relative;
    margin: 0 auto;
    height: 20px;
    margin-bottom: 16px; }
    .auth__or:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: #ddd; }
    .auth__or div {
      background-color: #f5f6fa;
      color: #ccc;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      padding: 0 8px;
      font-size: 14px; }
  .auth__register {
    margin-bottom: 24px;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center; }
    .auth__register a {
      color: #000000;
      font-weight: 700;
      padding-right: 8px;
      margin-right: 8px;
      display: block;
      position: relative;
      text-decoration: none; }
      .auth__register a:last-child {
        padding-right: 0;
        margin-right: 0; }
        .auth__register a:last-child:before {
          display: none; }
      .auth__register a:before {
        content: '';
        position: absolute;
        height: 50%;
        width: 1px;
        background-color: #101924;
        top: 25%;
        right: 0; }
      .auth__register a:hover {
        text-decoration: underline; }
  .auth .md-card-title {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0; }
  .auth .md-card-text {
    padding: 0 !important; }
  .auth .rmd-text-field {
    background-color: #fff; }
  .auth .form-builder__actions {
    margin-top: 16px;
    margin-bottom: 16px; }
    .auth .form-builder__actions button {
      width: 100%; }

.google-btn {
  width: 200px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  display: block;
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none; }
  .google-btn .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff; }
  .google-btn .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px; }
  .google-btn .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }
  .google-btn:hover {
    box-shadow: 0 0 6px #4285f4; }
  .google-btn:active {
    background: #1669F2; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.auth-layout {
  width: 100vw;
  height: 100vh;
  display: flex; }
  .auth-layout__form {
    width: 1000vw;
    background-color: #f5f6fa;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 96px 40px 40px; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.app {
  display: grid;
  grid-template-columns: 350px minmax(100px, 1fr); }
  .app__page {
    margin: 0 auto; }

@charset "UTF-8";
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  background-color: #F4F4F4 !important; }
  body *, html * {
    box-sizing: border-box; }

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0; }

/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

html {
  background-color: #fafafa;
  background-color: var(--rmd-theme-background, #fafafa);
  color: #212121;
  color: var(--rmd-theme-text-primary-on-background, #212121);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

[hidden] {
  display: none !important; }

body.rmd-utils--touch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer; }

.rmd-grid {
  display: grid;
  grid-column-gap: 1rem;
  grid-column-gap: var(--rmd-grid-gutter, 1rem);
  grid-row-gap: 1rem;
  grid-row-gap: var(--rmd-grid-gutter, 1rem);
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(var(--rmd-grid-cols, 4), 1fr);
  padding: 1rem; }
  @media screen and (min-width: 48em) {
    .rmd-grid {
      --rmd-grid-cols: 8; } }
  @media screen and (min-width: 64.0625em) {
    .rmd-grid {
      --rmd-grid-cols: 12; } }
  .rmd-grid > * {
    min-width: 0; }
  .rmd-grid--no-padding {
    padding: 0; }
  .rmd-grid__cell--2 {
    grid-column-end: span 2; }
  .rmd-grid__cell--3 {
    grid-column-end: span 3; }
  .rmd-grid__cell--4 {
    grid-column-end: span 4; }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--4 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--5 {
    grid-column-end: span 5; }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--5 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--6 {
    grid-column-end: span 6; }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--6 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--7 {
    grid-column-end: span 7; }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--7 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--8 {
    grid-column-end: span 8; }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .rmd-grid__cell--8 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--8 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--9 {
    grid-column-end: span 9; }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .rmd-grid__cell--9 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--9 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--10 {
    grid-column-end: span 10; }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .rmd-grid__cell--10 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--10 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--11 {
    grid-column-end: span 11; }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .rmd-grid__cell--11 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--11 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
  .rmd-grid__cell--12 {
    grid-column-end: span 12; }
    @media screen and (min-width: 64.0625em) {
      .rmd-grid__cell--12 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .rmd-grid__cell--12 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }
    @media screen and (max-width: 47.9375em) {
      .rmd-grid__cell--12 {
        grid-column: 1/span 4;
        grid-column: 1/span var(--rmd-grid-cols, 4); } }

.rmd-grid-list {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding: 0.5rem; }
  .rmd-grid-list__cell {
    width: calc(9.375rem - calc(0.5rem * 2));
    width: calc(var(--rmd-cell-size, 9.375rem) - calc(var(--rmd-cell-margin, 0.5rem) * 2));
    margin: 0.5rem;
    margin: var(--rmd-cell-margin, 0.5rem); }
    .rmd-grid-list__cell--square {
      height: calc(9.375rem - calc(0.5rem * 2));
      height: calc(var(--rmd-cell-size, 9.375rem) - calc(var(--rmd-cell-margin, 0.5rem) * 2)); }

:root {
  --rmd-alert-background-color: #323232;
  --rmd-alert-color: #fff;
  --rmd-alert-light-background-color: #323232;
  --rmd-alert-light-color: #fff;
  --rmd-alert-dark-background-color: #323232;
  --rmd-alert-dark-color: #fff; }

.rmd-snackbar {
  display: flex;
  justify-content: center;
  left: 0;
  margin: 1rem;
  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: 40; }
  .rmd-snackbar--top {
    top: 0; }
  .rmd-snackbar--bottom {
    bottom: 0; }

.rmd-toast {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit;
  background-color: #323232;
  background-color: var(--rmd-alert-background-color, #323232);
  color: #fff;
  color: var(--rmd-alert-color, #fff);
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  min-height: 3rem;
  min-width: 21.5rem;
  padding: 0 1rem;
  pointer-events: auto;
  -webkit-transform-origin: center;
          transform-origin: center; }
  .rmd-toast--padded {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem; }
  .rmd-toast--action {
    padding-right: 0; }
    [dir='rtl'] .rmd-toast--action {
      padding-right: 1rem;
      padding-left: 0; }
  .rmd-toast--two-lines {
    min-height: 4.25rem; }
  .rmd-toast--stacked {
    flex-direction: column;
    padding-bottom: 0; }
  .rmd-toast--enter {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: opacity 0.15s, -webkit-transform 0.15s;
    transition: opacity 0.15s, transform 0.15s;
    transition: opacity 0.15s, transform 0.15s, -webkit-transform 0.15s; }
  .rmd-toast--enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  .rmd-toast--exit {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  .rmd-toast--exit-active {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: opacity 0.15s, -webkit-transform 0.15s;
    transition: opacity 0.15s, transform 0.15s;
    transition: opacity 0.15s, transform 0.15s, -webkit-transform 0.15s; }
  .rmd-toast__message {
    margin-right: auto;
    display: inline-flex;
    flex: 1 1 auto;
    flex-wrap: wrap; }
    [dir='rtl'] .rmd-toast__message {
      margin-right: 0;
      margin-left: auto; }
    .rmd-toast__message--action {
      padding-right: 1rem; }
      [dir='rtl'] .rmd-toast__message--action {
        padding-right: 0;
        padding-left: 1rem; }
    .rmd-toast__message p {
      margin: 0;
      width: 100%; }
  .rmd-toast__action {
    display: inline-flex;
    flex-shrink: 0;
    margin: 0 0.5rem; }
    .rmd-toast__action--stacked {
      align-self: flex-end;
      margin-bottom: 0.5rem;
      margin-top: 0.25rem; }

:root {
  --rmd-avatar-background-color: #616161;
  --rmd-avatar-border-color: rgba(0, 0, 0, 0.12);
  --rmd-avatar-border-radius: 50%;
  --rmd-avatar-color: #f5f5f5;
  --rmd-avatar-font-size: 1.5rem;
  --rmd-avatar-size: 2.5rem; }

.rmd-avatar {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  --rmd-icon-color: currentColor;
  border-radius: 50%;
  border-radius: var(--rmd-avatar-border-radius, 50%);
  font-size: 1.5rem;
  font-size: var(--rmd-avatar-font-size, 1.5rem);
  height: 2.5rem;
  height: var(--rmd-avatar-size, 2.5rem);
  width: 2.5rem;
  width: var(--rmd-avatar-size, 2.5rem);
  border-color: rgba(0, 0, 0, 0.12);
  border-color: var(--rmd-avatar-border-color, rgba(0, 0, 0, 0.12));
  background-color: #616161;
  background-color: var(--rmd-avatar-background-color, #616161);
  color: #f5f5f5;
  color: var(--rmd-avatar-color, #f5f5f5);
  align-items: center;
  border: 1px solid;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: 1.75rem;
  overflow: hidden; }
  .rmd-avatar__image {
    height: 100%;
    width: auto; }

.rmd-avatar--red {
  --rmd-avatar-background-color: #d50000;
  --rmd-avatar-color: #ffebee; }

.rmd-avatar--pink {
  --rmd-avatar-background-color: #d81b60;
  --rmd-avatar-color: #fff; }

.rmd-avatar--purple {
  --rmd-avatar-background-color: #7b1fa2;
  --rmd-avatar-color: #e1bee7; }

.rmd-avatar--deep-purple {
  --rmd-avatar-background-color: #311b92;
  --rmd-avatar-color: #d1c4e9; }

.rmd-avatar--indigo {
  --rmd-avatar-background-color: #3949ab;
  --rmd-avatar-color: #c5cae9; }

.rmd-avatar--blue {
  --rmd-avatar-background-color: #2962ff;
  --rmd-avatar-color: #fff; }

.rmd-avatar--light-blue {
  --rmd-avatar-background-color: #4fc3f7;
  --rmd-avatar-color: #311b92; }

.rmd-avatar--cyan {
  --rmd-avatar-background-color: #26c6da;
  --rmd-avatar-color: #004d40; }

.rmd-avatar--teal {
  --rmd-avatar-background-color: #1de9b6;
  --rmd-avatar-color: #004d40; }

.rmd-avatar--green {
  --rmd-avatar-background-color: #2e7d32;
  --rmd-avatar-color: #e8f5e9; }

.rmd-avatar--light-green {
  --rmd-avatar-background-color: #aed581;
  --rmd-avatar-color: #1b5e20; }

.rmd-avatar--lime {
  --rmd-avatar-background-color: #d4e157;
  --rmd-avatar-color: #00695c; }

.rmd-avatar--yellow {
  --rmd-avatar-background-color: #ff0;
  --rmd-avatar-color: #795548; }

.rmd-avatar--amber {
  --rmd-avatar-background-color: #ffca28;
  --rmd-avatar-color: #4e342e; }

.rmd-avatar--orange {
  --rmd-avatar-background-color: #fb8c00;
  --rmd-avatar-color: #212121; }

.rmd-avatar--deep-orange {
  --rmd-avatar-background-color: #ff3d00;
  --rmd-avatar-color: #212121; }

.rmd-avatar--brown {
  --rmd-avatar-background-color: #795548;
  --rmd-avatar-color: #efebe9; }

.rmd-avatar--grey {
  --rmd-avatar-background-color: #616161;
  --rmd-avatar-color: #f5f5f5; }

.rmd-avatar--blue-grey {
  --rmd-avatar-background-color: #455a64;
  --rmd-avatar-color: #eceff1; }

:root {
  --rmd-badge-background-color: rgba(0, 0, 0, 0.2);
  --rmd-badge-color: #fff;
  --rmd-badge-border-radius: 50%;
  --rmd-badge-font-size: 0.625rem;
  --rmd-badge-size: 1.5rem;
  --rmd-badge-top: 0;
  --rmd-badge-right: 0; }

.rmd-badge-container {
  display: inline-flex;
  position: relative; }

.rmd-badge {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  letter-spacing: 0.03571em;
  text-decoration: inherit;
  text-transform: inherit;
  border-radius: 50%;
  border-radius: var(--rmd-badge-border-radius, 50%);
  height: 1.5rem;
  height: var(--rmd-badge-size, 1.5rem);
  width: 1.5rem;
  width: var(--rmd-badge-size, 1.5rem);
  font-size: 0.625rem;
  font-size: var(--rmd-badge-font-size, 0.625rem);
  right: 0;
  right: var(--rmd-badge-right, 0);
  top: 0;
  top: var(--rmd-badge-top, 0);
  align-items: center;
  display: inline-flex;
  justify-content: center;
  pointer-events: none;
  position: absolute; }
  [dir='rtl'] .rmd-badge {
    left: 0;
    left: var(--rmd-badge-right, 0);
    right: auto; }
  .rmd-badge--primary {
    background-color: #B92754;
    background-color: var(--rmd-theme-primary, #B92754);
    color: #000;
    color: var(--rmd-theme-on-primary, #000); }
  .rmd-badge--secondary {
    background-color: #000;
    background-color: var(--rmd-theme-secondary, #000);
    color: #000;
    color: var(--rmd-theme-on-secondary, #000); }
  .rmd-badge--default {
    background-color: rgba(0, 0, 0, 0.2);
    background-color: var(--rmd-badge-background-color, rgba(0, 0, 0, 0.2));
    color: #fff;
    color: var(--rmd-badge-color, #fff); }

:root {
  --rmd-button-text-border-radius: 0.5rem;
  --rmd-button-text-horizontal-padding: 1rem;
  --rmd-button-text-vertical-padding: 0;
  --rmd-button-text-height: 2.25rem;
  --rmd-button-text-min-width: 4rem;
  --rmd-button-icon-border-radius: 50%;
  --rmd-button-icon-size: 3rem;
  --rmd-button-background-color: transparent;
  --rmd-button-color: var(--rmd-theme-text-primary-on-background, #212121);
  --rmd-button-outline: #999;
  --rmd-button-outline-width: 1px; }

.rmd-button {
  --rmd-icon-color: currentColor;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  background-color: var(--rmd-button-background-color, transparent);
  color: #212121;
  color: var(--rmd-button-color, var(--rmd-theme-text-primary-on-background, #212121));
  align-items: center;
  border-width: 0;
  display: inline-flex;
  justify-content: center;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .rmd-button:focus {
    outline-style: none; }
  .rmd-button::-moz-focus-inner {
    border: 0; }
  .rmd-button:focus {
    outline-style: none; }
  .rmd-button::-moz-focus-inner {
    border: 0; }
  .rmd-utils--keyboard .rmd-button:focus::before {
    box-shadow: inset 0 0 0 0.125rem #2196f3;
    box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
  .rmd-button::before {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: inherit;
    content: '';
    pointer-events: none;
    z-index: 0;
    background-color: transparent;
    background-color: var(--rmd-states-background-color, transparent);
    transition: background-color 0.15s; }
  .rmd-button:disabled, .rmd-button[aria-disabled='true'] {
    --rmd-states-hover-color: transparent; }
  .rmd-button:not(:disabled):not([aria-disabled='true']):hover {
    cursor: pointer; }
  .rmd-button:hover {
    --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--keyboard .rmd-button:focus {
    --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
    .rmd-utils--keyboard .rmd-button:focus:hover {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--touch .rmd-button:focus, .rmd-utils--touch .rmd-button:hover {
    --rmd-states-background-color: transparent; }
  .rmd-button.rmd-states--pressed {
    --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
    .rmd-utils--keyboard .rmd-button.rmd-states--pressed {
      --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
  .rmd-button--text {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    letter-spacing: 0.08929em;
    text-decoration: none;
    text-transform: uppercase;
    --rmd-icon-size: 1.125rem;
    border-radius: 0.5rem;
    border-radius: var(--rmd-button-text-border-radius, 0.5rem);
    min-height: 2.25rem;
    min-height: var(--rmd-button-text-height, 2.25rem);
    min-width: 4rem;
    min-width: var(--rmd-button-text-min-width, 4rem);
    padding: 0 1rem;
    padding: var(--rmd-button-text-vertical-padding, 0) var(--rmd-button-text-horizontal-padding, 1rem); }
  .rmd-button--icon {
    border-radius: 50%;
    border-radius: var(--rmd-button-icon-border-radius, 50%);
    height: 3rem;
    height: var(--rmd-button-icon-size, 3rem);
    width: 3rem;
    width: var(--rmd-button-icon-size, 3rem);
    padding: 0; }
  .rmd-button--outline {
    box-shadow: inset 0 0 0 1px #999;
    box-shadow: inset 0 0 0 var(--rmd-button-outline-width, 1px) var(--rmd-button-outline, #999); }
  .rmd-button--contained {
    --rmd-button-background-color: var(--rmd-theme-surface, #fff);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    --rmd-states-background-color: transparent; }
    .rmd-button--contained::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      opacity: 0;
      transition: opacity 0.15s; }
    .rmd-button--contained.rmd-states--pressed::after {
      opacity: 1; }
  .rmd-button--disabled {
    --rmd-button-color: var(--rmd-theme-text-disabled-on-background, #9e9e9e);
    --rmd-button-outline: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
  .rmd-button--primary {
    --rmd-button-background-color: var(--rmd-theme-primary, #B92754);
    --rmd-button-color: var(--rmd-theme-on-primary, #000); }
  .rmd-button--text-primary {
    --rmd-button-color: var(--rmd-theme-primary, #B92754);
    --rmd-button-outline: var(--rmd-theme-primary, #B92754); }
  .rmd-button--secondary {
    --rmd-button-background-color: var(--rmd-theme-secondary, #000);
    --rmd-button-color: var(--rmd-theme-on-secondary, #000); }
  .rmd-button--text-secondary {
    --rmd-button-color: var(--rmd-theme-secondary, #000);
    --rmd-button-outline: var(--rmd-theme-secondary, #000); }
  .rmd-button--warning {
    --rmd-button-background-color: var(--rmd-theme-warning, #ff6e40);
    --rmd-button-color: var(--rmd-theme-on-warning, #fff); }
  .rmd-button--text-warning {
    --rmd-button-color: var(--rmd-theme-warning, #ff6e40);
    --rmd-button-outline: var(--rmd-theme-warning, #ff6e40); }
  .rmd-button--error {
    --rmd-button-background-color: var(--rmd-theme-error, #f44336);
    --rmd-button-color: var(--rmd-theme-on-error, #000); }
  .rmd-button--text-error {
    --rmd-button-color: var(--rmd-theme-error, #f44336);
    --rmd-button-outline: var(--rmd-theme-error, #f44336); }

.rmd-button-unstyled {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-width: 0;
  display: inline-flex;
  position: relative; }
  .rmd-button-unstyled:focus {
    outline-style: none; }
  .rmd-button-unstyled::-moz-focus-inner {
    border: 0; }
  .rmd-button-unstyled::before {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: inherit;
    content: '';
    pointer-events: none;
    z-index: 0; }
  .rmd-utils--keyboard .rmd-button-unstyled:focus::before {
    box-shadow: inset 0 0 0 0.125rem #2196f3;
    box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
  .rmd-button-unstyled:not(:disabled):hover {
    cursor: pointer; }

.rmd-fab {
  position: fixed;
  z-index: 30; }
  .rmd-fab--tl {
    left: 1.5rem;
    top: 1.5rem; }
    [dir='rtl'] .rmd-fab--tl {
      left: auto;
      right: 1.5rem; }
  .rmd-fab--tr {
    right: 1.5rem;
    top: 1.5rem; }
    [dir='rtl'] .rmd-fab--tr {
      right: auto;
      left: 1.5rem; }
  .rmd-fab--bl {
    bottom: 1.5rem;
    left: 1.5rem; }
    [dir='rtl'] .rmd-fab--bl {
      left: auto;
      right: 1.5rem; }
  .rmd-fab--br {
    bottom: 1.5rem;
    right: 1.5rem; }
    [dir='rtl'] .rmd-fab--br {
      right: auto;
      left: 1.5rem; }

:root {
  --rmd-app-bar-background-color: transparent;
  --rmd-app-bar-color: initial;
  --rmd-app-bar-primary: var(--rmd-theme-primary, #B92754);
  --rmd-app-bar-on-primary: var(--rmd-theme-on-primary, #000);
  --rmd-app-bar-secondary: var(--rmd-theme-secondary, #000);
  --rmd-app-bar-on-secondary: var(--rmd-theme-on-secondary, #000);
  --rmd-app-bar-default-background-color: #f5f5f5;
  --rmd-app-bar-default-light-background-color: #f5f5f5;
  --rmd-app-bar-default-dark-background-color: #212121;
  --rmd-app-bar-default-color: #000;
  --rmd-app-bar-default-light-color: #000;
  --rmd-app-bar-default-dark-color: #fff;
  --rmd-app-bar-height: 3.5rem;
  --rmd-app-bar-dense-height: 3rem;
  --rmd-app-bar-prominent-height: 7rem;
  --rmd-app-bar-prominent-dense-height: 6rem; }

.rmd-app-bar {
  background-color: transparent;
  background-color: var(--rmd-app-bar-background-color, transparent);
  color: initial;
  color: var(--rmd-app-bar-color, initial);
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  width: 100%; }
  .rmd-app-bar--fixed {
    --rmd-theme-surface: var(--rmd-app-bar-background-color, transparent);
    left: 0;
    position: fixed;
    right: 0;
    z-index: 10; }
  .rmd-app-bar--fixed-elevation {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  .rmd-app-bar--top {
    top: 0; }
  .rmd-app-bar--right {
    right: 0; }
  .rmd-app-bar--bottom {
    bottom: 0; }
  .rmd-app-bar--left {
    left: 0; }
  .rmd-app-bar--primary {
    --rmd-app-bar-background-color: var(--rmd-app-bar-primary, var(--rmd-theme-primary, #B92754));
    --rmd-app-bar-color: var(--rmd-app-bar-on-primary, var(--rmd-theme-on-primary, #000)); }
  .rmd-app-bar--secondary {
    --rmd-app-bar-background-color: var(--rmd-app-bar-secondary, var(--rmd-theme-secondary, #000));
    --rmd-app-bar-color: var(--rmd-app-bar-on-secondary, var(--rmd-theme-on-secondary, #000)); }
  .rmd-app-bar--default {
    --rmd-app-bar-background-color: var(--rmd-app-bar-default-background-color, #f5f5f5);
    --rmd-app-bar-color: var(--rmd-app-bar-default-color, #000); }
  .rmd-app-bar--wrap {
    flex-wrap: wrap; }
  .rmd-app-bar--normal {
    height: 3.5rem;
    height: var(--rmd-app-bar-height, 3.5rem); }
  .rmd-app-bar--dense {
    height: 3rem;
    height: var(--rmd-app-bar-dense-height, 3rem); }
  .rmd-app-bar--prominent {
    height: 7rem;
    height: var(--rmd-app-bar-prominent-height, 7rem); }
  .rmd-app-bar--prominent-dense {
    height: 6rem;
    height: var(--rmd-app-bar-prominent-dense-height, 6rem); }
  .rmd-app-bar__nav {
    margin-left: 0.25rem;
    margin-right: 1.25rem;
    flex-shrink: 0; }
    [dir='rtl'] .rmd-app-bar__nav {
      margin-right: 0.25rem;
      margin-left: 1.25rem; }
    .rmd-app-bar__nav--inherit {
      color: inherit; }
  .rmd-app-bar__title {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    margin-left: 1rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 0;
    margin-top: 0; }
    [dir='rtl'] .rmd-app-bar__title {
      margin-left: 0;
      margin-right: 1rem; }
    .rmd-app-bar__title:focus {
      outline-style: none; }
    .rmd-app-bar__title::-moz-focus-inner {
      border: 0; }
    .rmd-app-bar__nav ~ .rmd-app-bar__title {
      margin-left: 0; }
      [dir='rtl'] .rmd-app-bar__nav ~ .rmd-app-bar__title {
        margin-left: 0;
        margin-right: 0; }
    .rmd-app-bar__title--keyline {
      margin-left: 4.5rem; }
      [dir='rtl'] .rmd-app-bar__title--keyline {
        margin-left: auto;
        margin-right: 4.5rem; }
    .rmd-app-bar__title--no-wrap {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .rmd-app-bar__title--inherit {
      color: inherit; }
  .rmd-app-bar__action {
    flex-shrink: 0; }
    .rmd-app-bar__action--last {
      margin-right: 0.25rem; }
      [dir='rtl'] .rmd-app-bar__action--last {
        margin-right: 0;
        margin-left: 0.25rem; }
    .rmd-app-bar__action--first {
      margin-left: auto; }
      [dir='rtl'] .rmd-app-bar__action--first {
        margin-left: 0;
        margin-right: auto; }
    .rmd-app-bar__action--inherit {
      color: inherit; }

.rmd-app-bar-offset {
  padding-top: 3.5rem;
  padding-top: var(--rmd-app-bar-height, 3.5rem); }
  .rmd-app-bar-offset--dense {
    padding-top: 3rem;
    padding-top: var(--rmd-app-bar-dense-height, 3rem); }
  .rmd-app-bar-offset--prominent {
    padding-top: 7rem;
    padding-top: var(--rmd-app-bar-prominent-height, 7rem); }
  .rmd-app-bar-offset--prominent-dense {
    padding-top: 6rem;
    padding-top: var(--rmd-app-bar-prominent-dense-height, 6rem); }

:root {
  --rmd-overlay-background-color: rgba(0, 0, 0, 0.4);
  --rmd-overlay-active-opacity: 1;
  --rmd-overlay-z-index: 30; }

.rmd-overlay {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  background-color: var(--rmd-overlay-background-color, rgba(0, 0, 0, 0.4));
  z-index: 30;
  z-index: var(--rmd-overlay-z-index, 30);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s; }
  .rmd-overlay:focus {
    outline-style: none; }
  .rmd-overlay::-moz-focus-inner {
    border: 0; }
  .rmd-overlay--active {
    opacity: 1;
    opacity: var(--rmd-overlay-active-opacity, 1); }
  .rmd-overlay--clickable {
    cursor: pointer; }
  .rmd-overlay--visible {
    pointer-events: auto; }

:root {
  --rmd-dialog-horizontal-margin: 2.5rem;
  --rmd-dialog-vertical-margin: 1.5rem;
  --rmd-dialog-min-width: 17.5rem;
  --rmd-dialog-header-padding: 1.5rem;
  --rmd-dialog-header-padding-bottom: 1.25rem;
  --rmd-dialog-content-padding: 1.5rem;
  --rmd-dialog-footer-padding: 0.5rem;
  --rmd-dialog-z-index: 30; }

.rmd-dialog-container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 30;
  z-index: var(--rmd-dialog-z-index, 30);
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1.5rem 2.5rem;
  margin: var(--rmd-dialog-vertical-margin, 1.5rem) var(--rmd-dialog-horizontal-margin, 2.5rem);
  pointer-events: none; }

.rmd-dialog-overlay {
  z-index: 30;
  z-index: var(--rmd-dialog-z-index, 30); }

.rmd-dialog {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --rmd-theme-background: var(--rmd-theme-surface, #fff);
  background-color: #fafafa;
  background-color: var(--rmd-theme-background, #fafafa);
  color: #212121;
  color: var(--rmd-theme-text-primary-on-background, #212121);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --rmd-app-bar-color: var(--rmd-theme-text-primary-on-background, #212121);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%; }
  .rmd-dialog:focus {
    outline-style: none; }
  .rmd-dialog::-moz-focus-inner {
    border: 0; }
  .rmd-dialog--centered {
    min-width: 17.5rem;
    min-width: var(--rmd-dialog-min-width, 17.5rem);
    pointer-events: auto; }
  .rmd-dialog--full-page {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 30;
    z-index: var(--rmd-dialog-z-index, 30); }
  .rmd-dialog--enter {
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem); }
  .rmd-dialog--enter-active {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .rmd-dialog--exit {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: opacity 0.15s, -webkit-transform 0.15s;
    transition: transform 0.15s, opacity 0.15s;
    transition: transform 0.15s, opacity 0.15s, -webkit-transform 0.15s; }
  .rmd-dialog--exit-active {
    opacity: 0;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem); }
  .rmd-dialog--fixed {
    z-index: 30;
    z-index: var(--rmd-dialog-z-index, 30); }
  .rmd-dialog--fixed-enter {
    -webkit-transform: scale(0);
            transform: scale(0); }
  .rmd-dialog--fixed-enter-active {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .rmd-dialog--fixed-exit {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: opacity 0.15s, -webkit-transform 0.15s;
    transition: transform 0.15s, opacity 0.15s;
    transition: transform 0.15s, opacity 0.15s, -webkit-transform 0.15s; }
  .rmd-dialog--fixed-exit-active {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); }
  .rmd-dialog__header {
    padding: 1.5rem;
    padding: var(--rmd-dialog-header-padding, 1.5rem);
    padding-bottom: 1.25rem;
    padding-bottom: var(--rmd-dialog-header-padding-bottom, 1.25rem);
    align-items: center;
    display: flex;
    flex: 0 0 auto; }
  .rmd-dialog__content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1 1 auto; }
    .rmd-dialog__content--padded {
      padding: 1.5rem;
      padding: var(--rmd-dialog-content-padding, 1.5rem); }
  .rmd-dialog__footer {
    padding: 0.5rem;
    padding: var(--rmd-dialog-footer-padding, 0.5rem);
    flex: 0 0 auto; }
    .rmd-dialog__footer--flex {
      display: flex; }
    .rmd-dialog__footer--flex-v {
      flex-direction: column; }
    .rmd-dialog__footer--start {
      justify-content: flex-start; }
    .rmd-dialog__footer--between {
      justify-content: space-between; }
    .rmd-dialog__footer--end {
      justify-content: flex-end; }
  .rmd-dialog__title {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 2.125rem;
    line-height: 2.5rem;
    font-weight: 400;
    letter-spacing: 0.00735em;
    text-decoration: inherit;
    text-transform: inherit;
    margin: 0; }

:root {
  --rmd-card-background-color: var(--rmd-theme-surface, #fff);
  --rmd-card-color: var(--rmd-theme-on-surface, #000);
  --rmd-card-secondary-color: var(--rmd-theme-text-primary-on-light, #212121); }

.rmd-card {
  --rmd-theme-background: var(--rmd-card-background-color, var(--rmd-theme-surface, #fff));
  --rmd-theme-text-primary-on-background: var(--rmd-card-color, var(--rmd-theme-on-surface, #000));
  --rmd-theme-text-secondary-on-background: var(--rmd-card-secondary-color, var(--rmd-theme-text-primary-on-light, #212121));
  background-color: #fff;
  background-color: var(--rmd-theme-surface, #fff);
  color: #212121;
  color: var(--rmd-theme-text-primary-on-background, #212121);
  border-radius: 0.25rem;
  display: inline-block; }
  .rmd-utils--mouse .rmd-card--raiseable {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: relative; }
    .rmd-utils--mouse .rmd-card--raiseable::before {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      opacity: 0;
      transition: opacity 0.15s; }
    .rmd-utils--mouse .rmd-card--raiseable:hover::before {
      opacity: 1; }
  .rmd-card--shadowed {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  .rmd-card--bordered {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border: 1px solid var(--rmd-divider-background-color, rgba(0, 0, 0, 0.12)); }
  .rmd-card--full-width {
    display: block;
    width: 100%; }
  .rmd-card--no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .rmd-card__header {
    --rmd-icon-text-spacing: 1rem;
    display: flex;
    padding: 1rem;
    padding-top: 1.5rem; }
    .rmd-card__header--top {
      align-items: flex-start; }
    .rmd-card__header--center {
      align-items: center; }
    .rmd-card__header--bottom {
      align-items: flex-end; }
  .rmd-card__header-addon {
    flex-shrink: 0; }
  .rmd-card__header-content {
    flex-grow: 1;
    min-width: 0; }
  .rmd-card__title {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit;
    margin-bottom: 0;
    margin-top: 0; }
    .rmd-card__title--small {
      line-height: 1.75rem;
      font-size: 1rem;
      letter-spacing: 0.00937em; }
  .rmd-card__subtitle {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    margin-bottom: 0;
    margin-top: 0; }
    .rmd-card__subtitle--secondary {
      color: #757575;
      color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-card__content--secondary {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-card__content--remove-margin p {
    margin-top: 0; }
    .rmd-card__content--remove-margin p:last-child {
      margin-bottom: 0; }
  .rmd-card__content--padded {
    padding: 1rem; }
  .rmd-card__content--extra-padding:last-child {
    padding-bottom: 1.5rem; }
  .rmd-card__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem; }
    .rmd-card__actions--start {
      justify-content: flex-start; }
    .rmd-card__actions--center {
      justify-content: center; }

:root {
  --rmd-chip-height: 2rem;
  --rmd-chip-border-radius: 1rem;
  --rmd-chip-themed-background-color: #65152e;
  --rmd-chip-themed-color: #fff;
  --rmd-chip-solid-background-color: #e0e0e0;
  --rmd-chip-solid-color: #000;
  --rmd-chip-solid-disabled: #f5f5f5;
  --rmd-chip-outline-background-color: #fff;
  --rmd-chip-outline-color: #000;
  --rmd-chip-outline-border-color: #e0e0e0;
  --rmd-chip-small-spacing: 0.25rem;
  --rmd-chip-medium-spacing: 0.5rem;
  --rmd-chip-large-spacing: 0.75rem; }

.rmd-chip {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  height: 2rem;
  height: var(--rmd-chip-height, 2rem);
  border-radius: 1rem;
  border-radius: var(--rmd-chip-border-radius, 1rem);
  padding-left: 0.75rem;
  padding-left: var(--rmd-chip-large-spacing, 0.75rem);
  padding-right: 0.75rem;
  padding-right: var(--rmd-chip-large-spacing, 0.75rem);
  --rmd-icon-size: 1.125rem;
  --rmd-avatar-size: 1.5rem;
  --rmd-progress-circular-size: 1.125rem;
  --rmd-progress-circular-width: 12;
  align-items: center;
  border-width: 0;
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;
  position: relative; }
  .rmd-chip:focus {
    outline-style: none; }
  .rmd-chip::-moz-focus-inner {
    border: 0; }
  .rmd-chip:focus {
    outline-style: none; }
  .rmd-chip::-moz-focus-inner {
    border: 0; }
  .rmd-utils--keyboard .rmd-chip:focus::before {
    box-shadow: inset 0 0 0 0.125rem #2196f3;
    box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
  .rmd-chip::before {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: inherit;
    content: '';
    pointer-events: none;
    z-index: 0;
    background-color: transparent;
    background-color: var(--rmd-states-background-color, transparent);
    transition: background-color 0.15s; }
  .rmd-chip:disabled, .rmd-chip[aria-disabled='true'] {
    --rmd-states-hover-color: transparent; }
  .rmd-chip:not(:disabled):not([aria-disabled='true']):hover {
    cursor: pointer; }
  .rmd-chip:hover {
    --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--touch .rmd-chip:focus, .rmd-utils--touch .rmd-chip:hover {
    --rmd-states-background-color: transparent; }
  .rmd-chip.rmd-states--pressed {
    --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
    .rmd-utils--keyboard .rmd-chip.rmd-states--pressed {
      --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
  .rmd-chip--selected {
    --rmd-states-background-color: var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16)); }
    .rmd-utils--touch .rmd-chip--selected:hover, .rmd-utils--touch .rmd-chip--selected:focus {
      --rmd-states-background-color: var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16)); }
  .rmd-chip--solid {
    box-shadow: none;
    position: relative;
    background-color: #e0e0e0;
    background-color: var(--rmd-chip-solid-background-color, #e0e0e0);
    color: #000;
    color: var(--rmd-chip-solid-color, #000); }
    .rmd-chip--solid::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      opacity: 0;
      transition: opacity 0.15s; }
    .rmd-chip--solid.rmd-states--pressed::after {
      opacity: 1; }
  .rmd-chip--outline {
    box-shadow: none;
    position: relative;
    background-color: #fff;
    background-color: var(--rmd-chip-outline-background-color, #fff);
    color: #000;
    color: var(--rmd-chip-outline-color, #000);
    box-shadow: inset 0 0 0 1px #e0e0e0;
    box-shadow: inset 0 0 0 1px var(--rmd-chip-outline-border-color, #e0e0e0); }
    .rmd-chip--outline::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      opacity: 0;
      transition: opacity 0.15s; }
    .rmd-chip--outline.rmd-states--pressed::after {
      opacity: 1; }
  .rmd-chip--themed {
    background-color: #65152e;
    background-color: var(--rmd-chip-themed-background-color, #65152e);
    color: #fff;
    color: var(--rmd-chip-themed-color, #fff);
    --rmd-icon-color: currentColor; }
  .rmd-chip--disabled {
    color: #9e9e9e;
    color: var(--rmd-theme-text-disabled-on-background, #9e9e9e);
    --rmd-icon-color: currentColor; }
  .rmd-chip--solid-disabled {
    background-color: #f5f5f5;
    background-color: var(--rmd-chip-solid-disabled, #f5f5f5); }
  .rmd-chip--leading-icon {
    padding-left: 0.25rem;
    padding-left: var(--rmd-chip-small-spacing, 0.25rem); }
    [dir='rtl'] .rmd-chip--leading-icon {
      padding-left: 0.75rem;
      padding-left: var(--rmd-chip-large-spacing, 0.75rem);
      padding-right: 0.25rem;
      padding-right: var(--rmd-chip-small-spacing, 0.25rem); }
  .rmd-chip--trailing-icon {
    padding-right: 0.5rem;
    padding-right: var(--rmd-chip-medium-spacing, 0.5rem); }
    [dir='rtl'] .rmd-chip--trailing-icon {
      padding-left: 0.5rem;
      padding-left: var(--rmd-chip-medium-spacing, 0.5rem);
      padding-right: 0.75rem;
      padding-right: var(--rmd-chip-large-spacing, 0.75rem); }
  .rmd-chip--surrounded {
    padding-left: 0.25rem;
    padding-left: var(--rmd-chip-small-spacing, 0.25rem);
    padding-right: 0.5rem;
    padding-right: var(--rmd-chip-medium-spacing, 0.5rem); }
    [dir='rtl'] .rmd-chip--surrounded {
      padding-left: 0.5rem;
      padding-left: var(--rmd-chip-medium-spacing, 0.5rem);
      padding-right: 0.25rem;
      padding-right: var(--rmd-chip-small-spacing, 0.25rem); }
  .rmd-chip__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 auto; }
  .rmd-chip__selected-icon {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    max-width: 0;
    transition: max-width 0.15s; }
    .rmd-chip__selected-icon--visible {
      max-width: 1.5rem;
      max-width: var(--rmd-icon-size, 1.5rem); }

:root {
  --rmd-divider-background-color: rgba(0, 0, 0, 0.12);
  --rmd-divider-background-color-on-light: rgba(0, 0, 0, 0.12);
  --rmd-divider-background-color-on-dark: rgba(255, 255, 255, 0.12);
  --rmd-divider-size: 1px;
  --rmd-divider-inset: 4rem;
  --rmd-divider-spacing: 0.25rem auto;
  --rmd-divider-vertical-spacing: auto 0.25rem;
  --rmd-divider-max-size: 100%; }

.rmd-divider {
  border-color: rgba(0, 0, 0, 0.12);
  border-color: var(--rmd-divider-background-color, rgba(0, 0, 0, 0.12));
  border-width: 1px;
  border-width: var(--rmd-divider-size, 1px);
  margin: 0.25rem auto;
  margin: var(--rmd-divider-spacing, 0.25rem auto);
  width: 100%;
  width: var(--rmd-divider-max-size, 100%);
  display: block;
  flex-shrink: 0; }
  .rmd-divider--vertical {
    border-left-width: 1px;
    border-left-width: var(--rmd-divider-size, 1px);
    border-left-color: rgba(0, 0, 0, 0.12);
    border-left-color: var(--rmd-divider-background-color, rgba(0, 0, 0, 0.12));
    height: 100%;
    height: var(--rmd-divider-max-size, 100%);
    margin: auto 0.25rem;
    margin: var(--rmd-divider-vertical-spacing, auto 0.25rem);
    width: 1px;
    width: var(--rmd-divider-size, 1px);
    border-bottom-style: none;
    border-left-style: inset;
    display: inline-block; }
  .rmd-divider--inset {
    margin-left: 4rem;
    margin-left: var(--rmd-divider-inset, 4rem);
    width: calc(100% - 4rem);
    width: calc(var(--rmd-divider-max-size, 100%) - var(--rmd-divider-inset, 4rem)); }
    [dir='rtl'] .rmd-divider--inset {
      margin-right: 4rem;
      margin-right: var(--rmd-divider-inset, 4rem);
      margin-left: auto; }

:root {
  --rmd-expansion-panel-spacing: 1rem;
  --rmd-expansion-panel-padding: 1rem;
  --rmd-expansion-panel-icon-spacing: 0.5rem; }

.rmd-expansion-panel--margin-top {
  margin-top: 1rem;
  margin-top: var(--rmd-expansion-panel-spacing, 1rem); }

.rmd-expansion-panel__header {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  padding: 1rem;
  padding: var(--rmd-expansion-panel-padding, 1rem);
  align-items: center;
  color: inherit;
  width: 100%; }

.rmd-expansion-panel__icon {
  padding-left: 0.5rem;
  padding-left: var(--rmd-expansion-panel-icon-spacing, 0.5rem);
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-left: auto; }
  [dir='rtl'] .rmd-expansion-panel__icon {
    padding-right: 0.5rem;
    padding-right: var(--rmd-expansion-panel-icon-spacing, 0.5rem);
    margin-left: 0;
    margin-right: auto;
    padding-left: 0; }

:root {
  --rmd-form-error-color: #f44336;
  --rmd-form-error-hover-color: #d50000;
  --rmd-form-active-color: var(--rmd-theme-secondary, #000);
  --rmd-form-disabled-color: var(--rmd-theme-text-disabled-on-background, #9e9e9e);
  --rmd-form-toggle-inset: 0.3125rem;
  --rmd-form-toggle-dense-inset: 0.25rem;
  --rmd-form-indeterminate-height: 0.15rem;
  --rmd-form-indeterminate-dense-height: 0.125rem;
  --rmd-form-track-background-color: rgba(0, 0, 0, 0.38);
  --rmd-form-floating-top: 1rem;
  --rmd-form-floating-dense-top: 0.9rem;
  --rmd-form-addon-margin-top: 0px;
  --rmd-form-text-active-color: #2196f3;
  --rmd-form-text-border-color: rgba(0, 0, 0, 0.12);
  --rmd-form-text-border-hover-color: rgba(0, 0, 0, 0.87);
  --rmd-form-text-filled-color: #f5f5f5;
  --rmd-form-text-height: 3rem;
  --rmd-form-text-label-height: 3.5rem;
  --rmd-form-text-label-dense-height: 3.25rem;
  --rmd-form-text-placeholder-height: 3rem;
  --rmd-form-text-placeholder-dense-height: 2.5rem;
  --rmd-form-textarea-padding: 0.5rem; }

.rmd-file-input {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1; }
  .rmd-utils--keyboard .rmd-file-input:focus + .rmd-file-input-label::before {
    box-shadow: inset 0 0 0 0.125rem #2196f3;
    box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
  .rmd-utils--keyboard .rmd-file-input:focus + .rmd-file-input-label {
    --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
  .rmd-utils--keyboard .rmd-file-input:hover {
    --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }

.rmd-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03571em;
  text-decoration: inherit;
  text-transform: inherit;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  font-size: 1em;
  transition: color 0.15s; }
  .rmd-label--active {
    color: #000;
    color: var(--rmd-form-active-color, var(--rmd-theme-secondary, #000)); }
  .rmd-label--error {
    color: #f44336;
    color: var(--rmd-form-error-color, #f44336); }
  .rmd-label--disabled {
    color: #9e9e9e;
    color: var(--rmd-form-disabled-color, var(--rmd-theme-text-disabled-on-background, #9e9e9e)); }

.rmd-floating-label {
  --rmd-form-active-color: var(--rmd-form-text-active-color, #2196f3);
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translate(0px, 1rem);
          transform: translate(0px, 1rem);
  -webkit-transform: translate(var(--rmd-form-text-padding-left, 0px), var(--rmd-form-floating-top, 1rem));
          transform: translate(var(--rmd-form-text-padding-left, 0px), var(--rmd-form-floating-top, 1rem));
  transition-property: background-color, color, font-size, -webkit-transform;
  transition-property: background-color, color, font-size, transform;
  transition-property: background-color, color, font-size, transform, -webkit-transform;
  will-change: background-color, color, font-size, transform; }
  [dir='rtl'] .rmd-floating-label {
    left: auto;
    right: 0;
    -webkit-transform: translate(calc(-1 * 0px), 1rem);
            transform: translate(calc(-1 * 0px), 1rem);
    -webkit-transform: translate(calc(-1 * var(--rmd-form-text-padding-left, 0px)), var(--rmd-form-floating-top, 1rem));
            transform: translate(calc(-1 * var(--rmd-form-text-padding-left, 0px)), var(--rmd-form-floating-top, 1rem)); }
  .rmd-floating-label--dense {
    --rmd-form-floating-top: var(--rmd-form-floating-dense-top, 0.9rem); }
  .rmd-floating-label--active {
    background-color: transparent;
    background-color: var(--rmd-form-label-active-background-color, transparent);
    padding: 0px;
    padding: var(--rmd-form-label-active-padding, 0px);
    font-size: 0.75em;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transform: translate(var(--rmd-form-label-left-offset, 0px), var(--rmd-form-label-top-offset, 0px));
            transform: translate(var(--rmd-form-label-left-offset, 0px), var(--rmd-form-label-top-offset, 0px));
    z-index: 1; }
    [dir='rtl'] .rmd-floating-label--active {
      -webkit-transform: translate(calc(-1 * 0px), 0px);
              transform: translate(calc(-1 * 0px), 0px);
      -webkit-transform: translate(calc(-1 * var(--rmd-form-label-left-offset, 0px)), var(--rmd-form-label-top-offset, 0px));
              transform: translate(calc(-1 * var(--rmd-form-label-left-offset, 0px)), var(--rmd-form-label-top-offset, 0px)); }
  .rmd-floating-label--inactive {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }

.rmd-toggle-container {
  align-items: center;
  display: flex; }
  .rmd-toggle-container--inline {
    display: inline-flex; }
  .rmd-toggle-container--stacked {
    flex-direction: column; }

.rmd-toggle {
  height: 3rem;
  height: var(--rmd-button-icon-size, 3rem);
  width: 3rem;
  width: var(--rmd-button-icon-size, 3rem);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  position: relative; }
  .rmd-toggle:focus {
    outline-style: none; }
  .rmd-toggle::-moz-focus-inner {
    border: 0; }
  .rmd-utils--keyboard .rmd-toggle--focused::before {
    box-shadow: inset 0 0 0 0.125rem #2196f3;
    box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
  .rmd-toggle::before {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: inherit;
    content: '';
    pointer-events: none;
    z-index: 0;
    background-color: transparent;
    background-color: var(--rmd-states-background-color, transparent);
    transition: background-color 0.15s; }
  .rmd-toggle:disabled, .rmd-toggle[aria-disabled='true'] {
    --rmd-states-hover-color: transparent; }
  .rmd-toggle:not(:disabled):not([aria-disabled='true']):hover {
    cursor: pointer; }
  .rmd-toggle:hover {
    --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--keyboard .rmd-toggle--focused {
    --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
    .rmd-utils--keyboard .rmd-toggle--focused:hover {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--touch .rmd-toggle:focus, .rmd-utils--touch .rmd-toggle:hover {
    --rmd-states-background-color: transparent; }
  .rmd-toggle.rmd-states--pressed {
    --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
    .rmd-utils--keyboard .rmd-toggle.rmd-states--pressed {
      --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
  .rmd-toggle--disabled {
    --rmd-states-hover-color: tranparent; }
  .rmd-toggle__input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1; }
    .rmd-toggle__input:focus {
      outline-style: none; }
    .rmd-toggle__input::-moz-focus-inner {
      border: 0; }
    .rmd-toggle__input:hover {
      cursor: pointer; }
    .rmd-toggle__input:disabled:hover {
      cursor: default; }
    .rmd-toggle__input:checked + .rmd-toggle__icon::before {
      opacity: 0; }
    .rmd-toggle__input:checked + .rmd-toggle__icon--indeterminate::before, .rmd-toggle__input:checked + .rmd-toggle__icon--indeterminate::after {
      opacity: 1; }
  .rmd-toggle__icon {
    height: 1.5rem;
    height: var(--rmd-icon-size, 1.5rem);
    width: 1.5rem;
    width: var(--rmd-icon-size, 1.5rem);
    color: #000;
    color: var(--rmd-form-active-color, var(--rmd-theme-secondary, #000));
    align-items: center;
    display: inline-flex;
    justify-content: center;
    pointer-events: none;
    position: absolute; }
    .rmd-toggle__icon .rmd-icon {
      color: inherit;
      fill: currentColor;
      height: inherit;
      width: inherit; }
    .rmd-toggle__icon--overlay::before {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0.3125rem;
      bottom: var(--rmd-form-toggle-inset, 0.3125rem);
      left: 0.3125rem;
      left: var(--rmd-form-toggle-inset, 0.3125rem);
      right: 0.3125rem;
      right: var(--rmd-form-toggle-inset, 0.3125rem);
      top: 0.3125rem;
      top: var(--rmd-form-toggle-inset, 0.3125rem);
      background-color: #fafafa;
      background-color: var(--rmd-theme-background, #fafafa);
      content: '';
      opacity: 1;
      position: absolute;
      transition: opacity 0.15s;
      z-index: 1; }
    .rmd-toggle__icon--indeterminate::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      background-color: #000;
      background-color: var(--rmd-form-active-color, var(--rmd-theme-secondary, #000));
      left: 0.3125rem;
      left: var(--rmd-form-toggle-inset, 0.3125rem);
      right: 0.3125rem;
      right: var(--rmd-form-toggle-inset, 0.3125rem);
      height: 0.15rem;
      height: var(--rmd-form-indeterminate-height, 0.15rem);
      content: '';
      opacity: 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      transition: opacity 0.15s;
      z-index: 2; }
    .rmd-toggle__icon--circle::before {
      border-radius: 50%; }
    .rmd-toggle__icon--disabled {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-toggle__icon--dense {
      bottom: 0.25rem;
      bottom: var(--rmd-form-toggle-dense-inset, 0.25rem);
      left: 0.25rem;
      left: var(--rmd-form-toggle-dense-inset, 0.25rem);
      right: 0.25rem;
      right: var(--rmd-form-toggle-dense-inset, 0.25rem);
      top: 0.25rem;
      top: var(--rmd-form-toggle-dense-inset, 0.25rem);
      height: 0.125rem;
      height: var(--rmd-form-indeterminate-dense-height, 0.125rem); }

.rmd-switch-container {
  padding: 0.5rem 0.625rem; }

.rmd-switch {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.38);
  background-color: var(--rmd-form-track-background-color, rgba(0, 0, 0, 0.38));
  border-radius: 0.5rem;
  height: 1rem;
  position: relative;
  transition: background-color 0.15s;
  width: 2.25rem; }
  .rmd-switch--disabled {
    --rmd-form-track-background-color: var(--rmd-form-disabled-color, var(--rmd-theme-text-disabled-on-background, #9e9e9e)); }
  .rmd-switch--async {
    --rmd-progress-circular-width: 12;
    --rmd-progress-circular-size: 1.25rem; }
  .rmd-switch__input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1; }
    .rmd-switch__input:focus {
      outline-style: none; }
    .rmd-switch__input::-moz-focus-inner {
      border: 0; }
    .rmd-switch__input:hover {
      cursor: pointer; }
    .rmd-switch__input:disabled:hover {
      cursor: default; }
    .rmd-utils--keyboard .rmd-switch__input:focus + .rmd-switch__ball::before {
      box-shadow: inset 0 0 0 0.125rem #2196f3;
      box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
    .rmd-switch__input:disabled + .rmd-switch__ball {
      --rmd-states-hover-color: transparent;
      cursor: auto; }
      .rmd-switch__input:disabled + .rmd-switch__ball::after {
        background-color: #9e9e9e;
        background-color: var(--rmd-form-disabled-color, var(--rmd-theme-text-disabled-on-background, #9e9e9e)); }
    .rmd-utils--keyboard .rmd-switch__input:focus + .rmd-switch__ball {
      --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
    .rmd-utils--keyboard .rmd-switch__input:focus:hover + .rmd-switch__ball {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
    .rmd-utils--touch .rmd-switch__input:focus + .rmd-switch__ball,
    .rmd-utils--touch .rmd-switch__input:hover + .rmd-switch__ball {
      --rmd-states-background-color: transparent; }
    .rmd-switch__input:checked + .rmd-switch__ball {
      -webkit-transform: translateX(1.5rem);
              transform: translateX(1.5rem); }
      [dir='rtl'] .rmd-switch__input:checked + .rmd-switch__ball {
        -webkit-transform: translateX(-1.5rem);
                transform: translateX(-1.5rem); }
      .rmd-switch__input:checked + .rmd-switch__ball::after {
        background-color: #000;
        background-color: var(--rmd-form-active-color, var(--rmd-theme-secondary, #000)); }
  .rmd-switch__ball {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    left: calc(-50% + 0.25rem);
    position: absolute;
    top: calc(-50% - 0.25rem);
    transition-duration: 0.15s;
    transition-property: background-color, -webkit-transform;
    transition-property: background-color, transform;
    transition-property: background-color, transform, -webkit-transform;
    width: 2.5rem;
    z-index: 1; }
    .rmd-switch__ball:focus {
      outline-style: none; }
    .rmd-switch__ball::-moz-focus-inner {
      border: 0; }
    [dir='rtl'] .rmd-switch__ball {
      left: auto;
      right: calc(-50% + 0.25rem); }
    .rmd-switch__ball::before {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      background-color: transparent;
      background-color: var(--rmd-states-background-color, transparent);
      transition: background-color 0.15s; }
    .rmd-switch__ball:hover {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08));
      cursor: pointer; }
    .rmd-switch__ball::after {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      background-color: #f2f2f2;
      border-radius: inherit;
      content: '';
      height: 1.25rem;
      left: 25%;
      pointer-events: none;
      position: absolute;
      top: 25%;
      width: 1.25rem;
      z-index: 1; }
  .rmd-switch__progress {
    background-color: #fff;
    border-radius: inherit;
    padding: 0.125rem;
    z-index: 2; }

.rmd-text-field-container {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  height: 3rem;
  height: var(--rmd-form-text-height, 3rem);
  align-items: center;
  display: flex;
  position: relative;
  transition: border-color 0.15s; }
  .rmd-text-field-container--hoverable:hover {
    border-color: rgba(0, 0, 0, 0.87);
    border-color: var(--rmd-form-text-border-hover-color, rgba(0, 0, 0, 0.87)); }
  .rmd-text-field-container--inline {
    display: inline-flex; }
  .rmd-text-field-container--label {
    --rmd-form-text-height: var(--rmd-form-text-label-height, 3.5rem); }
  .rmd-text-field-container--error {
    --rmd-icon-color: var(--rmd-form-error-color, #f44336);
    --rmd-form-text-active-color: var(--rmd-form-error-color, #f44336);
    --rmd-form-text-border-color: var(--rmd-form-error-color, #f44336);
    --rmd-form-text-border-hover-color: var(--rmd-form-error-hover-color, #d50000); }
  .rmd-text-field-container--disabled {
    --rmd-icon-color: var(--rmd-form-disabled-color, var(--rmd-theme-text-disabled-on-background, #9e9e9e)); }
  .rmd-text-field-container--underline {
    border-color: rgba(0, 0, 0, 0.12);
    border-color: var(--rmd-form-text-border-color, rgba(0, 0, 0, 0.12));
    border-bottom-style: solid;
    border-bottom-width: 1px; }
    .rmd-text-field-container--underline::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      background-color: #2196f3;
      background-color: var(--rmd-form-text-active-color, #2196f3);
      bottom: -1px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      transition: -webkit-transform 0.15s;
      transition: transform 0.15s;
      transition: transform 0.15s, -webkit-transform 0.15s;
      z-index: 1; }
  .rmd-text-field-container--underline-labelled {
    --rmd-form-text-padding-top: 1rem;
    --rmd-form-addon-margin-top: 0.5rem; }
  .rmd-text-field-container--filled {
    --rmd-form-text-padding-left: 0.75rem;
    --rmd-form-text-padding-right: 0.75rem;
    --rmd-form-label-left-offset: 0.75rem;
    background-color: #f5f5f5;
    background-color: var(--rmd-form-text-filled-color, #f5f5f5);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .rmd-text-field-container--filled:focus {
      outline-style: none; }
    .rmd-text-field-container--filled::-moz-focus-inner {
      border: 0; }
    .rmd-utils--keyboard .rmd-text-field-container--filled:focus::before {
      box-shadow: inset 0 0 0 0.125rem #2196f3;
      box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
    .rmd-text-field-container--filled::before {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      background-color: transparent;
      background-color: var(--rmd-states-background-color, transparent);
      transition: background-color 0.15s; }
    .rmd-text-field-container--filled:disabled, .rmd-text-field-container--filled[aria-disabled='true'] {
      --rmd-states-hover-color: transparent; }
    .rmd-text-field-container--filled:hover {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
    .rmd-utils--keyboard .rmd-text-field-container--filled:focus {
      --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
      .rmd-utils--keyboard .rmd-text-field-container--filled:focus:hover {
        --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
    .rmd-utils--touch .rmd-text-field-container--filled:focus, .rmd-utils--touch .rmd-text-field-container--filled:hover {
      --rmd-states-background-color: transparent; }
    .rmd-text-field-container--filled.rmd-states--pressed {
      --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
      .rmd-utils--keyboard .rmd-text-field-container--filled.rmd-states--pressed {
        --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
  .rmd-text-field-container--underline-left::after {
    -webkit-transform-origin: left;
            transform-origin: left; }
  .rmd-text-field-container--underline-center::after {
    -webkit-transform-origin: center;
            transform-origin: center; }
  .rmd-text-field-container--underline-right::after {
    -webkit-transform-origin: right;
            transform-origin: right; }
  .rmd-text-field-container--underline-active::after {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .rmd-text-field-container--underline-left-addon {
    --rmd-form-text-padding-left: calc(var(--rmd-icon-size, 1.5rem) + 1rem);
    --rmd-form-label-left-offset: 0.5rem; }
  .rmd-text-field-container--underline-right-addon {
    --rmd-form-text-padding-right: calc(var(--rmd-icon-size, 1.5rem) + 1rem); }
  .rmd-text-field-container--outline {
    --rmd-form-text-padding-left: 1rem;
    --rmd-form-text-padding-right: 1rem;
    --rmd-form-label-left-offset: 0.75rem;
    --rmd-form-label-top-offset: -50%;
    --rmd-form-label-active-padding: 0 0.25rem;
    --rmd-form-label-active-background-color: var(--rmd-theme-background, #fafafa);
    border-color: rgba(0, 0, 0, 0.12);
    border-color: var(--rmd-form-text-border-color, rgba(0, 0, 0, 0.12));
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 1px; }
    .rmd-text-field-container--outline::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      box-shadow: 0 0 0 2px #2196f3;
      box-shadow: 0 0 0 2px var(--rmd-form-text-active-color, #2196f3);
      opacity: 0;
      transition: opacity 0.15s; }
  .rmd-text-field-container--outline-active::after {
    opacity: 1; }
  .rmd-text-field-container--outline-left {
    --rmd-form-text-padding-left: calc( var(--rmd-icon-size, 1.5rem) + 1.5rem); }
  .rmd-text-field-container--outline-right {
    --rmd-form-text-padding-right: calc(var(--rmd-icon-size, 1.5rem) + 1rem); }
  .rmd-text-field-container--dense {
    --rmd-form-text-height: var(--rmd-form-text-placeholder-dense-height, 2.5rem); }
  .rmd-text-field-container--dense-label {
    --rmd-form-text-height: var(--rmd-form-text-label-dense-height, 3.25rem); }
  .rmd-text-field-container--dense-placeholder {
    --rmd-form-text-padding-top: 0.25rem; }

.rmd-text-field {
  padding-left: 0px;
  padding-left: var(--rmd-form-text-padding-left, 0px);
  padding-right: 0px;
  padding-right: var(--rmd-form-text-padding-right, 0px);
  padding-top: 0px;
  padding-top: var(--rmd-form-text-padding-top, 0px);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03571em;
  text-decoration: inherit;
  text-transform: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-width: 0;
  color: inherit;
  font-size: 1em;
  width: 100%;
  flex: 1 1 auto;
  height: 100%; }
  [dir='rtl'] .rmd-text-field {
    padding-left: 0px;
    padding-left: var(--rmd-form-text-padding-right, 0px);
    padding-right: 0px;
    padding-right: var(--rmd-form-text-padding-left, 0px); }
  .rmd-text-field:focus {
    outline-style: none; }
  .rmd-text-field::-moz-focus-inner {
    border: 0; }
  .rmd-text-field::-webkit-input-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-text-field:-ms-input-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-text-field::-moz-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-text-field:-moz-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-text-field:placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-text-field[disabled] {
    color: #9e9e9e;
    color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-text-field[disabled]::-webkit-input-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-text-field[disabled]:-ms-input-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-text-field[disabled]::-moz-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-text-field[disabled]:-moz-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-text-field[disabled]:placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
  .rmd-text-field--floating::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-text-field--floating:-ms-input-placeholder {
    color: transparent;
    -ms-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-text-field--floating::-moz-placeholder {
    color: transparent;
    -moz-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-text-field--floating:-moz-placeholder {
    color: transparent;
    -moz-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-text-field--floating:placeholder {
    color: transparent;
    transition: color 0.15s; }
  .rmd-text-field--floating[disabled]::-webkit-input-placeholder {
    color: transparent; }
  .rmd-text-field--floating[disabled]:-ms-input-placeholder {
    color: transparent; }
  .rmd-text-field--floating[disabled]::-moz-placeholder {
    color: transparent; }
  .rmd-text-field--floating[disabled]:-moz-placeholder {
    color: transparent; }
  .rmd-text-field--floating[disabled]:placeholder {
    color: transparent; }
  .rmd-text-field--floating:focus::-webkit-input-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-text-field--floating:focus:-ms-input-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-text-field--floating:focus::-moz-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-text-field--floating:focus:-moz-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-text-field--floating:focus:placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }

.rmd-text-field-addon {
  height: 1.5rem;
  height: var(--rmd-icon-size, 1.5rem);
  width: 1.5rem;
  width: var(--rmd-icon-size, 1.5rem);
  top: auto;
  top: var(--rmd-form-addon-top, auto);
  margin-top: 0px;
  margin-top: var(--rmd-form-addon-margin-top, 0px);
  position: absolute; }
  .rmd-text-field-addon:first-child {
    left: 0px;
    left: var(--rmd-form-label-left-offset, 0px); }
    [dir='rtl'] .rmd-text-field-addon:first-child {
      left: auto;
      right: 0px;
      right: var(--rmd-form-label-left-offset, 0px); }
  .rmd-text-field-addon:last-child {
    right: 0.5rem; }
    [dir='rtl'] .rmd-text-field-addon:last-child {
      right: auto;
      left: 0.5rem; }
  .rmd-text-field-addon--presentational {
    pointer-events: none; }

.rmd-password--offset {
  --rmd-form-text-padding-right: 0; }

.rmd-password__input--offset {
  margin-right: 3rem;
  margin-right: var(--rmd-button-icon-size, 3rem); }
  [dir='rtl'] .rmd-password__input--offset {
    margin-right: 0;
    margin-left: 3rem;
    margin-left: var(--rmd-button-icon-size, 3rem); }

.rmd-password__toggle {
  right: 0;
  position: absolute; }
  [dir='rtl'] .rmd-password__toggle {
    right: auto;
    left: 0; }

.rmd-textarea-container {
  --rmd-form-addon-top: 1rem;
  height: auto;
  max-width: 100%;
  padding-top: calc( 0px + 0.5rem);
  padding-top: calc( var(--rmd-form-text-padding-top, 0px) + var(--rmd-form-textarea-padding, 0.5rem)); }
  .rmd-textarea-container--animate {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: height 0.15s; }
  .rmd-textarea-container--cursor:hover {
    cursor: text; }
  .rmd-textarea-container__inner {
    height: 100%;
    width: 100%; }
    .rmd-textarea-container__inner--animate {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition: height 0.15s; }

.rmd-textarea {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  padding-left: 0px;
  padding-left: var(--rmd-form-text-padding-left, 0px);
  padding-right: 0px;
  padding-right: var(--rmd-form-text-padding-right, 0px);
  min-height: 3rem;
  min-height: var(--rmd-form-text-height, 3rem);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03571em;
  text-decoration: inherit;
  text-transform: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-width: 0;
  color: inherit;
  font-size: 1em;
  width: 100%;
  flex: 1 1 auto;
  height: 100%; }
  [dir='rtl'] .rmd-textarea {
    padding-left: 0px;
    padding-left: var(--rmd-form-text-padding-right, 0px);
    padding-right: 0px;
    padding-right: var(--rmd-form-text-padding-left, 0px); }
  .rmd-textarea:focus {
    outline-style: none; }
  .rmd-textarea::-moz-focus-inner {
    border: 0; }
  .rmd-textarea::-webkit-input-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-textarea:-ms-input-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-textarea::-moz-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-textarea:-moz-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-textarea:placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-textarea[disabled] {
    color: #9e9e9e;
    color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-textarea[disabled]::-webkit-input-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-textarea[disabled]:-ms-input-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-textarea[disabled]::-moz-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-textarea[disabled]:-moz-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-textarea[disabled]:placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
  .rmd-textarea--floating::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-textarea--floating:-ms-input-placeholder {
    color: transparent;
    -ms-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-textarea--floating::-moz-placeholder {
    color: transparent;
    -moz-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-textarea--floating:-moz-placeholder {
    color: transparent;
    -moz-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-textarea--floating:placeholder {
    color: transparent;
    transition: color 0.15s; }
  .rmd-textarea--floating[disabled]::-webkit-input-placeholder {
    color: transparent; }
  .rmd-textarea--floating[disabled]:-ms-input-placeholder {
    color: transparent; }
  .rmd-textarea--floating[disabled]::-moz-placeholder {
    color: transparent; }
  .rmd-textarea--floating[disabled]:-moz-placeholder {
    color: transparent; }
  .rmd-textarea--floating[disabled]:placeholder {
    color: transparent; }
  .rmd-textarea--floating:focus::-webkit-input-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-textarea--floating:focus:-ms-input-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-textarea--floating:focus::-moz-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-textarea--floating:focus:-moz-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-textarea--floating:focus:placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-textarea--rh {
    resize: horizontal; }
  .rmd-textarea--rv {
    resize: vertical; }
  .rmd-textarea--rn {
    overflow: hidden;
    resize: none; }
  .rmd-textarea--scrollable {
    overflow: auto; }
  .rmd-textarea--mask {
    height: auto;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: -1; }

.rmd-form-message {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  color: #757575;
  color: var(--rmd-theme-text-secondary-on-background, #757575);
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  min-height: 2rem; }
  .rmd-form-message--filled {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .rmd-form-message--outline {
    padding-left: 1rem;
    padding-right: 1rem; }
  .rmd-form-message--error {
    color: #f44336;
    color: var(--rmd-form-error-color, #f44336); }
  .rmd-form-message__message {
    margin: 0; }
  .rmd-form-message__counter {
    margin-left: auto;
    padding-left: 0.5rem;
    flex-shrink: 0;
    white-space: nowrap; }
    [dir='rtl'] .rmd-form-message__counter {
      margin-left: 0;
      margin-right: auto; }
    [dir='rtl'] .rmd-form-message__counter {
      padding-left: 0;
      padding-right: 0.5rem; }

.rmd-native-select-container--multi {
  min-height: 3rem;
  min-height: var(--rmd-form-text-height, 3rem);
  --rmd-form-addon-top: 1rem;
  height: auto; }

.rmd-native-select-container--padded {
  padding-top: calc( 0.75rem + 0px);
  padding-top: calc( 0.75rem + var(--rmd-form-text-padding-top, 0px)); }

.rmd-native-select {
  padding-left: 0px;
  padding-left: var(--rmd-form-text-padding-left, 0px);
  padding-right: 0px;
  padding-right: var(--rmd-form-text-padding-right, 0px);
  padding-top: 0px;
  padding-top: var(--rmd-form-text-padding-top, 0px);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03571em;
  text-decoration: inherit;
  text-transform: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-width: 0;
  color: inherit;
  font-size: 1em;
  width: 100%;
  flex: 1 1 auto;
  height: 100%; }
  [dir='rtl'] .rmd-native-select {
    padding-left: 0px;
    padding-left: var(--rmd-form-text-padding-right, 0px);
    padding-right: 0px;
    padding-right: var(--rmd-form-text-padding-left, 0px); }
  .rmd-native-select:focus {
    outline-style: none; }
  .rmd-native-select::-moz-focus-inner {
    border: 0; }
  .rmd-native-select::-webkit-input-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-native-select:-ms-input-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-native-select::-moz-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-native-select:-moz-placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-native-select:placeholder {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit; }
  .rmd-native-select[disabled] {
    color: #9e9e9e;
    color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-native-select[disabled]::-webkit-input-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-native-select[disabled]:-ms-input-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-native-select[disabled]::-moz-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-native-select[disabled]:-moz-placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-native-select[disabled]:placeholder {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
  .rmd-native-select--floating::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-native-select--floating:-ms-input-placeholder {
    color: transparent;
    -ms-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-native-select--floating::-moz-placeholder {
    color: transparent;
    -moz-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-native-select--floating:-moz-placeholder {
    color: transparent;
    -moz-transition: color 0.15s;
    transition: color 0.15s; }
  .rmd-native-select--floating:placeholder {
    color: transparent;
    transition: color 0.15s; }
  .rmd-native-select--floating[disabled]::-webkit-input-placeholder {
    color: transparent; }
  .rmd-native-select--floating[disabled]:-ms-input-placeholder {
    color: transparent; }
  .rmd-native-select--floating[disabled]::-moz-placeholder {
    color: transparent; }
  .rmd-native-select--floating[disabled]:-moz-placeholder {
    color: transparent; }
  .rmd-native-select--floating[disabled]:placeholder {
    color: transparent; }
  .rmd-native-select--floating:focus::-webkit-input-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-native-select--floating:focus:-ms-input-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-native-select--floating:focus::-moz-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-native-select--floating:focus:-moz-placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-native-select--floating:focus:placeholder {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-native-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 #212121;
    text-shadow: 0 0 var(--rmd-theme-text-primary-on-background, #212121); }
  .rmd-native-select--icon {
    padding-right: 1.5rem;
    padding-right: var(--rmd-icon-size, 1.5rem);
    -webkit-appearance: none;
            appearance: none; }
  .rmd-native-select--multi {
    padding-top: 0; }
  .rmd-native-select__icon {
    right: 0;
    display: inline-flex;
    pointer-events: none;
    position: absolute; }
    [dir='rtl'] .rmd-native-select__icon {
      right: auto;
      left: 0; }
  .rmd-native-select__label {
    pointer-events: none; }

.rmd-listbox {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .rmd-listbox:focus {
    outline-style: none; }
  .rmd-listbox::-moz-focus-inner {
    border: 0; }
  .rmd-listbox--temporary {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    background-color: var(--rmd-theme-surface, #fff);
    color: #000;
    color: var(--rmd-theme-on-surface, #000);
    z-index: 30; }

.rmd-option {
  --rmd-list-item-horizontal-padding: 1.5rem; }
  .rmd-option--selected {
    background-color: #0d47a1;
    color: #fff; }
    .rmd-option--selected::after {
      left: 0.5rem;
      content: "\2713";
      position: absolute; }
      [dir='rtl'] .rmd-option--selected::after {
        left: auto;
        right: 0.5rem; }
  .rmd-utils--keyboard .rmd-option--focused {
    box-shadow: inset 0 0 0 2px #2196f3; }

.rmd-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .rmd-select:focus {
    outline-style: none; }
  .rmd-select::-moz-focus-inner {
    border: 0; }
  .rmd-select--disabled {
    color: #9e9e9e;
    color: var(--rmd-theme-text-disabled-on-background, #9e9e9e);
    cursor: default; }
  .rmd-select__value {
    padding-left: 0px;
    padding-left: var(--rmd-form-text-padding-left, 0px);
    padding-right: 0px;
    padding-right: var(--rmd-form-text-padding-right, 0px);
    padding-top: 0px;
    padding-top: var(--rmd-form-text-padding-top, 0px);
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.03571em;
    text-decoration: inherit;
    text-transform: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    [dir='rtl'] .rmd-select__value {
      padding-left: 0px;
      padding-left: var(--rmd-form-text-padding-right, 0px);
      padding-right: 0px;
      padding-right: var(--rmd-form-text-padding-left, 0px); }
    .rmd-select__value--placeholder {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      color: #757575;
      color: var(--rmd-theme-text-secondary-on-background, #757575);
      color: transparent;
      transition: color 0.15s; }
    .rmd-select__value--placeholder-active {
      color: #757575;
      color: var(--rmd-theme-text-secondary-on-background, #757575); }

.rmd-fieldset--unstyled {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0; }

.rmd-fieldset__legend {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03571em;
  text-decoration: inherit;
  text-transform: inherit; }
  .rmd-fieldset__legend--sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px; }

:root {
  --rmd-icon-color: var(--rmd-theme-text-icon-on-background, #757575);
  --rmd-icon-size: 1.5rem;
  --rmd-icon-dense-size: 1.25rem;
  --rmd-icon-text-spacing: 0.5rem;
  --rmd-icon-rotate-to: rotate(180deg);
  --rmd-icon-rotate-from: rotate(0deg); }

.rmd-icon {
  flex-shrink: 0;
  -webkit-user-select: none;
          user-select: none; }
  .rmd-icon--forced-font {
    font-size: 1.5rem !important;
    font-size: var(--rmd-icon-size, 1.5rem) !important; }
  .rmd-icon--forced-size {
    height: 1.5rem !important;
    height: var(--rmd-icon-size, 1.5rem) !important;
    width: 1.5rem !important;
    width: var(--rmd-icon-size, 1.5rem) !important; }
  .rmd-icon--dense {
    --rmd-icon-size: var(--rmd-icon-dense-size, 1.25rem); }
  .rmd-icon--font {
    color: #757575;
    color: var(--rmd-icon-color, var(--rmd-theme-text-icon-on-background, #757575));
    font-size: 1.5rem;
    font-size: var(--rmd-icon-size, 1.5rem);
    text-align: center; }
  .rmd-icon--svg {
    fill: #757575;
    fill: var(--rmd-icon-color, var(--rmd-theme-text-icon-on-background, #757575));
    height: 1.5rem;
    height: var(--rmd-icon-size, 1.5rem);
    width: 1.5rem;
    width: var(--rmd-icon-size, 1.5rem); }
    .rmd-icon--svg * {
      pointer-events: none; }
  .rmd-icon--before {
    margin-right: 0.5rem;
    margin-right: var(--rmd-icon-text-spacing, 0.5rem); }
    [dir='rtl'] .rmd-icon--before {
      margin-right: 0;
      margin-left: 0.5rem;
      margin-left: var(--rmd-icon-text-spacing, 0.5rem); }
  .rmd-icon--after {
    margin-left: 0.5rem;
    margin-left: var(--rmd-icon-text-spacing, 0.5rem); }
    [dir='rtl'] .rmd-icon--after {
      margin-left: 0;
      margin-right: 0.5rem;
      margin-right: var(--rmd-icon-text-spacing, 0.5rem); }
  .rmd-icon--above {
    margin-bottom: 0.5rem;
    margin-bottom: var(--rmd-icon-text-spacing, 0.5rem); }
  .rmd-icon--below {
    margin-top: 0.5rem;
    margin-top: var(--rmd-icon-text-spacing, 0.5rem); }

.rmd-text-icon-spacing {
  align-items: center;
  display: inline-flex; }

.rmd-icon-rotator {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform: var(--rmd-icon-rotate-from, rotate(0deg));
          transform: var(--rmd-icon-rotate-from, rotate(0deg)); }
  .rmd-icon-rotator--animate {
    transition: -webkit-transform 0.15s linear;
    transition: transform 0.15s linear;
    transition: transform 0.15s linear, -webkit-transform 0.15s linear; }
  .rmd-icon-rotator--rotated {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform: var(--rmd-icon-rotate-to, rotate(180deg));
            transform: var(--rmd-icon-rotate-to, rotate(180deg)); }

:root {
  --rmd-link-color: #2196f3;
  --rmd-link-hover-color: #42a5f5;
  --rmd-link-visited-color: #1e88e5; }

.rmd-link {
  color: #2196f3;
  color: var(--rmd-link-color, #2196f3);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transition: color 0.15s; }
  .rmd-utils--keyboard .rmd-link:focus {
    outline: 0.125rem solid #2196f3; }
  .rmd-link--flex-centered {
    align-items: center;
    display: inline-flex; }
  .rmd-link:visited {
    color: #1e88e5;
    color: var(--rmd-link-visited-color, #1e88e5); }
  .rmd-link:hover {
    color: #42a5f5;
    color: var(--rmd-link-hover-color, #42a5f5); }

.rmd-link-skip {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 10000; }
  .rmd-link-skip:active, .rmd-link-skip:focus {
    clip: auto;
    -webkit-clip-path: none;
            clip-path: none;
    height: auto;
    margin: auto;
    overflow: visible;
    white-space: normal;
    width: auto; }
  .rmd-link-skip:focus {
    outline-style: none; }
  .rmd-link-skip::-moz-focus-inner {
    border: 0; }
  .rmd-link-skip--styled {
    color: #000;
    color: var(--rmd-theme-on-primary, #000);
    left: 50%;
    padding: 0.25rem 1rem;
    top: 0.25rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .rmd-utils--keyboard .rmd-link-skip--styled:focus, .rmd-utils--keyboard .rmd-link-skip--styled:active {
      outline: 0.25rem dashed #000; }

:root {
  --rmd-list-vertical-padding: 0.5rem;
  --rmd-list-horizontal-padding: 0;
  --rmd-list-font-size: 1rem;
  --rmd-list-text-keyline: 4.5rem;
  --rmd-list-item-height: 3rem;
  --rmd-list-item-medium-height: 3.5rem;
  --rmd-list-item-large-height: 4rem;
  --rmd-list-item-extra-large-height: 4.5rem;
  --rmd-list-item-three-line-height: 5.5rem;
  --rmd-list-item-vertical-padding: 0.5rem;
  --rmd-list-item-horizontal-padding: 1rem;
  --rmd-list-item-secondary-three-line-height: 3rem;
  --rmd-list-dense-font-size: 0.8125rem;
  --rmd-list-dense-vertical-padding: 0.25rem;
  --rmd-list-dense-horizontal-padding: 0;
  --rmd-list-dense-item-height: 2.5rem;
  --rmd-list-dense-item-medium-height: 3rem;
  --rmd-list-dense-item-large-height: 3.5rem;
  --rmd-list-dense-item-extra-large-height: 4rem;
  --rmd-list-dense-item-three-line-height: 5rem;
  --rmd-list-dense-item-secondary-three-line-height: 2.25rem;
  --rmd-list-media-size: 3.5rem;
  --rmd-list-media-spacing: 1rem;
  --rmd-list-media-large-size: 6.25rem; }

.rmd-list {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  --rmd-divider-inset: var(--rmd-list-text-keyline, 4.5rem);
  list-style-type: none;
  margin: 0;
  font-size: 1rem;
  font-size: var(--rmd-list-font-size, 1rem);
  line-height: 1.5rem;
  padding: 0.5rem 0;
  padding: var(--rmd-list-vertical-padding, 0.5rem) var(--rmd-list-horizontal-padding, 0); }
  .rmd-list--horizontal {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 0.5rem;
    padding: var(--rmd-list-horizontal-padding, 0) var(--rmd-list-vertical-padding, 0.5rem); }
  .rmd-list--dense {
    --rmd-list-font-size: var(--rmd-list-dense-font-size, 0.8125rem);
    --rmd-list-vertical-padding: var(--rmd-list-dense-vertical-padding, 0.25rem);
    --rmd-list-horizontal-padding: var(--rmd-list-dense-horizontal-padding, 0); }

.rmd-list-item {
  min-height: 3rem;
  min-height: var(--rmd-list-item-height, 3rem);
  align-items: center;
  display: flex;
  padding: 0.5rem 1rem;
  padding: var(--rmd-list-item-vertical-padding, 0.5rem) var(--rmd-list-item-horizontal-padding, 1rem);
  position: relative; }
  .rmd-list-item--clickable {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .rmd-list-item--clickable:focus {
      outline-style: none; }
    .rmd-list-item--clickable::-moz-focus-inner {
      border: 0; }
    .rmd-list-item--clickable:focus {
      outline-style: none; }
    .rmd-list-item--clickable::-moz-focus-inner {
      border: 0; }
    .rmd-utils--keyboard .rmd-list-item--clickable:focus::before {
      box-shadow: inset 0 0 0 0.125rem #2196f3;
      box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
    .rmd-list-item--clickable::before {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      background-color: transparent;
      background-color: var(--rmd-states-background-color, transparent);
      transition: background-color 0.15s; }
    .rmd-list-item--clickable:disabled, .rmd-list-item--clickable[aria-disabled='true'] {
      --rmd-states-hover-color: transparent; }
    .rmd-list-item--clickable:not(:disabled):not([aria-disabled='true']):hover {
      cursor: pointer; }
    .rmd-list-item--clickable:hover {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
    .rmd-utils--keyboard .rmd-list-item--clickable:focus {
      --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
      .rmd-utils--keyboard .rmd-list-item--clickable:focus:hover {
        --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
    .rmd-utils--touch .rmd-list-item--clickable:focus, .rmd-utils--touch .rmd-list-item--clickable:hover {
      --rmd-states-background-color: transparent; }
    .rmd-list-item--clickable.rmd-states--pressed {
      --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
      .rmd-utils--keyboard .rmd-list-item--clickable.rmd-states--pressed {
        --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
  .rmd-list-item[aria-disabled] {
    color: #9e9e9e;
    color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
  .rmd-list-item--link {
    color: inherit;
    text-decoration: none; }
  .rmd-list-item--medium {
    --rmd-list-item-height: var(--rmd-list-item-medium-height, 3.5rem); }
  .rmd-list-item--large {
    --rmd-list-item-height: var(--rmd-list-item-large-height, 4rem); }
  .rmd-list-item--extra-large {
    --rmd-list-item-height: var(--rmd-list-item-extra-large-height, 4.5rem); }
  .rmd-list-item--three-lines {
    --rmd-list-item-height: var(--rmd-list-item-three-line-height, 5.5rem); }
    .rmd-list-item--three-lines .rmd-list-item__text--secondary {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      max-height: 3rem;
      max-height: var(--rmd-list-item-secondary-three-line-height, 3rem);
      line-height: 1.42857;
      white-space: normal; }
  .rmd-list-item--dense {
    --rmd-list-item-height: var(--rmd-list-dense-item-height, 2.5rem);
    --rmd-list-item-medium-height: var(--rmd-list-dense-item-medium-height, 3rem);
    --rmd-list-item-large-height: var(--rmd-list-dense-item-large-height, 3.5rem);
    --rmd-list-item-extra-large-height: var(--rmd-list-dense-item-extra-large-height, 4rem);
    --rmd-list-item-three-line-height: var(--rmd-list-dense-item-three-line-height, 5rem);
    --rmd-list-item-secondary-three-line-height: var(--rmd-list-dense-item-secondary-three-line-height, 2.25rem); }
  .rmd-list-item__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    flex-grow: 1;
    z-index: 1; }
    [dir='rtl'] .rmd-list-item__text {
      margin-left: auto; }
    .rmd-list-item__text--secondary {
      color: #757575;
      color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-list-item__addon {
    flex-shrink: 0; }
    .rmd-list-item__addon--top {
      align-self: flex-start; }
    .rmd-list-item__addon--bottom {
      align-self: flex-end; }
    .rmd-list-item__addon--before {
      --rmd-icon-text-spacing: calc(var(--rmd-list-text-keyline, 4.5rem) - var(--rmd-list-item-horizontal-padding, 1rem) - var(--rmd-icon-size, 1.5rem)); }
    .rmd-list-item__addon--avatar-before {
      --rmd-icon-text-spacing: calc(var(--rmd-list-text-keyline, 4.5rem) - var(--rmd-list-item-horizontal-padding, 1rem) - var(--rmd-avatar-size, 2.5rem)); }
    .rmd-list-item__addon--media {
      --rmd-icon-text-spacing: var(--rmd-list-media-spacing, 1rem);
      width: 3.5rem;
      width: var(--rmd-list-media-size, 3.5rem); }
    .rmd-list-item__addon--media-large {
      --rmd-list-media-size: var(--rmd-list-media-large-size, 6.25rem); }

.rmd-list-subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit;
  color: #757575;
  color: var(--rmd-theme-text-secondary-on-background, #757575);
  min-height: 3rem;
  min-height: var(--rmd-list-item-height, 3rem);
  align-items: center;
  display: flex;
  padding: 0.5rem 1rem;
  padding: var(--rmd-list-item-vertical-padding, 0.5rem) var(--rmd-list-item-horizontal-padding, 1rem);
  position: relative; }
  .rmd-list-subheader--inset {
    padding-left: 4.5rem;
    padding-left: var(--rmd-list-text-keyline, 4.5rem); }
    [dir='rtl'] .rmd-list-subheader--inset {
      padding-left: 1rem;
      padding-left: var(--rmd-list-item-horizontal-padding, 1rem);
      padding-right: 4.5rem;
      padding-right: var(--rmd-list-text-keyline, 4.5rem); }

.rmd-media-container {
  display: inline-block;
  position: relative; }
  .rmd-media-container--auto img {
    height: auto;
    width: 100%; }
  .rmd-media-container--aspect-ratio img {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .rmd-media-container--auto > svg {
    height: auto;
    width: 100%; }
  .rmd-media-container--aspect-ratio > svg {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .rmd-media-container--auto iframe {
    height: auto;
    width: 100%; }
  .rmd-media-container--aspect-ratio iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .rmd-media-container--auto video {
    height: auto;
    width: 100%; }
  .rmd-media-container--aspect-ratio video {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .rmd-media-container--auto embed {
    height: auto;
    width: 100%; }
  .rmd-media-container--aspect-ratio embed {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .rmd-media-container--auto object {
    height: auto;
    width: 100%; }
  .rmd-media-container--aspect-ratio object {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .rmd-media-container--aspect-ratio {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0; }
  .rmd-media-container--16-9 {
    padding-bottom: 56.25%; }
  .rmd-media-container--4-3 {
    padding-bottom: 75%; }
  .rmd-media-container--1-1 {
    padding-bottom: 100%; }
  .rmd-media-container--full-width {
    display: block;
    width: 100%; }

.rmd-media {
  height: auto;
  width: 100%; }

.rmd-media-overlay {
  --rmd-theme-text-secondary-on-background: var(--rmd-theme-text-secondary-on-dark, #b3b3b3);
  --rmd-theme-background: rgba(0, 0, 0, 0.54);
  --rmd-theme-text-primary-on-background: var(--rmd-theme-text-primary-on-dark, #d9d9d9);
  background-color: #fafafa;
  background-color: var(--rmd-theme-background, #fafafa);
  color: #212121;
  color: var(--rmd-theme-text-primary-on-background, #212121);
  padding: 1rem;
  position: absolute;
  z-index: 1; }
  .rmd-media-overlay--top {
    left: 0;
    right: 0;
    top: 0; }
  .rmd-media-overlay--right {
    bottom: 0;
    top: 0;
    width: 30%;
    right: 0; }
  .rmd-media-overlay--bottom {
    left: 0;
    right: 0;
    bottom: 0; }
  .rmd-media-overlay--left {
    bottom: 0;
    top: 0;
    width: 30%;
    left: 0; }
  .rmd-media-overlay--middle {
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .rmd-media-overlay--center {
    bottom: 0;
    top: 0;
    width: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .rmd-media-overlay--absolute-center {
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }

:root {
  --rmd-menu-background-color: var(--rmd-theme-surface, #fff);
  --rmd-menu-color: var(--rmd-theme-on-surface, #000);
  --rmd-menu-min-width: 7rem;
  --rmd-menu-icon-spacing: 1rem;
  --rmd-menu-z-index: 30; }

.rmd-menu {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  background-color: var(--rmd-menu-background-color, var(--rmd-theme-surface, #fff));
  color: #000;
  color: var(--rmd-menu-color, var(--rmd-theme-on-surface, #000));
  min-width: 7rem;
  min-width: var(--rmd-menu-min-width, 7rem);
  z-index: 30;
  z-index: var(--rmd-menu-z-index, 30); }
  .rmd-menu:focus {
    outline-style: none; }
  .rmd-menu::-moz-focus-inner {
    border: 0; }
  .rmd-menu--horizontal {
    display: flex;
    flex-wrap: nowrap; }

.rmd-menu-item {
  --rmd-icon-text-spacing: var(--rmd-menu-icon-spacing, 1rem); }

:root {
  --rmd-progress-color: #B92754;
  --rmd-progress-background-color: rgba(185, 39, 84, 0.4);
  --rmd-progress-linear-size: 0.25rem;
  --rmd-progress-circular-size: 3rem;
  --rmd-progress-circular-width: 6; }

.rmd-linear-progress {
  background-color: rgba(185, 39, 84, 0.4);
  background-color: var(--rmd-progress-background-color, rgba(185, 39, 84, 0.4));
  height: 0.25rem;
  height: var(--rmd-progress-linear-size, 0.25rem);
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .rmd-linear-progress--vertical {
    width: 0.25rem;
    width: var(--rmd-progress-linear-size, 0.25rem);
    display: inline-block;
    height: auto; }
  .rmd-linear-progress__bar--horizontal {
    left: 0; }
    [dir='rtl'] .rmd-linear-progress__bar--horizontal {
      left: auto;
      right: 0; }
  .rmd-linear-progress__bar--horizontal-reverse {
    left: auto;
    right: 0; }
    [dir='rtl'] .rmd-linear-progress__bar--horizontal-reverse {
      right: auto;
      left: 0; }
  .rmd-linear-progress__bar--vertical {
    bottom: 0;
    left: 0;
    right: 0; }
  .rmd-linear-progress__bar--vertical-reverse {
    bottom: auto;
    top: 0; }
  .rmd-linear-progress__bar--determinate {
    background-color: #B92754;
    background-color: var(--rmd-progress-color, #B92754);
    height: 0.25rem;
    height: var(--rmd-progress-linear-size, 0.25rem);
    position: absolute;
    z-index: 0; }
  .rmd-linear-progress__bar--animate {
    transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
    transition: width 0.15s, height 0.15s; }
  .rmd-linear-progress__bar--indeterminate::before, .rmd-linear-progress__bar--indeterminate::after {
    background-color: #B92754;
    background-color: var(--rmd-progress-color, #B92754);
    height: 0.25rem;
    height: var(--rmd-progress-linear-size, 0.25rem);
    position: absolute;
    z-index: 0;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    left: 0;
    right: 100%;
    -webkit-animation-duration: 2.4s;
            animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    content: '';
    will-change: left, right; }
    [dir='rtl'] .rmd-linear-progress__bar--indeterminate::before, [dir='rtl'] .rmd-linear-progress__bar--indeterminate::after {
      right: 0;
      left: 100%; }
  .rmd-linear-progress__bar--indeterminate::before {
    -webkit-animation-name: rmd-progress-bar;
            animation-name: rmd-progress-bar; }
  .rmd-linear-progress__bar--indeterminate::after {
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
    -webkit-animation-name: rmd-progress-bar-short;
            animation-name: rmd-progress-bar-short; }
  .rmd-linear-progress__bar--indeterminate-reverse::before {
    -webkit-animation-name: rmd-progress-bar-reverse;
            animation-name: rmd-progress-bar-reverse; }
  .rmd-linear-progress__bar--indeterminate-reverse::after {
    -webkit-animation-name: rmd-progress-bar-reverse-short;
            animation-name: rmd-progress-bar-reverse-short; }
  .rmd-linear-progress__bar--indeterminate-vertical::before, .rmd-linear-progress__bar--indeterminate-vertical::after {
    width: 0.25rem;
    width: var(--rmd-progress-linear-size, 0.25rem);
    height: auto;
    left: 0;
    right: 0; }
  .rmd-linear-progress__bar--indeterminate-vertical::before {
    -webkit-animation-name: rmd-progress-bar-vertical;
            animation-name: rmd-progress-bar-vertical; }
  .rmd-linear-progress__bar--indeterminate-vertical::after {
    -webkit-animation-name: rmd-progress-bar-vertical-short;
            animation-name: rmd-progress-bar-vertical-short; }
  .rmd-linear-progress__bar--indeterminate-vertical-reverse::before {
    -webkit-animation-name: rmd-progress-bar-vertical-reverse;
            animation-name: rmd-progress-bar-vertical-reverse; }
  .rmd-linear-progress__bar--indeterminate-vertical-reverse::after {
    -webkit-animation-name: rmd-progress-bar-vertical-reverse-short;
            animation-name: rmd-progress-bar-vertical-reverse-short; }

@-webkit-keyframes rmd-progress-bar {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes rmd-progress-bar {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@-webkit-keyframes rmd-progress-bar-short {
  0% {
    left: -200%;
    right: 100%; }
  40% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@keyframes rmd-progress-bar-short {
  0% {
    left: -200%;
    right: 100%; }
  40% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@-webkit-keyframes rmd-progress-bar-reverse {
  0% {
    left: 100%;
    right: -35%; }
  60% {
    left: -90%;
    right: 100%; }
  100% {
    left: -90%;
    right: 100%; } }

@keyframes rmd-progress-bar-reverse {
  0% {
    left: 100%;
    right: -35%; }
  60% {
    left: -90%;
    right: 100%; }
  100% {
    left: -90%;
    right: 100%; } }

@-webkit-keyframes rmd-progress-bar-reverse-short {
  0% {
    left: 100%;
    right: -200%; }
  40% {
    left: -8%;
    right: 107%; }
  100% {
    left: -8%;
    right: 107%; } }

@keyframes rmd-progress-bar-reverse-short {
  0% {
    left: 100%;
    right: -200%; }
  40% {
    left: -8%;
    right: 107%; }
  100% {
    left: -8%;
    right: 107%; } }

@-webkit-keyframes rmd-progress-bar-vertical {
  0% {
    bottom: -35%;
    top: 100%; }
  60% {
    bottom: 100%;
    top: -90%; }
  100% {
    bottom: 100%;
    top: -90%; } }

@keyframes rmd-progress-bar-vertical {
  0% {
    bottom: -35%;
    top: 100%; }
  60% {
    bottom: 100%;
    top: -90%; }
  100% {
    bottom: 100%;
    top: -90%; } }

@-webkit-keyframes rmd-progress-bar-vertical-short {
  0% {
    bottom: -200%;
    top: 100%; }
  40% {
    bottom: 107%;
    top: -8%; }
  100% {
    bottom: 107%;
    top: -8%; } }

@keyframes rmd-progress-bar-vertical-short {
  0% {
    bottom: -200%;
    top: 100%; }
  40% {
    bottom: 107%;
    top: -8%; }
  100% {
    bottom: 107%;
    top: -8%; } }

@-webkit-keyframes rmd-progress-bar-vertical-reverse {
  0% {
    bottom: 100%;
    top: -35%; }
  60% {
    bottom: -90%;
    top: 100%; }
  100% {
    bottom: -90%;
    top: 100%; } }

@keyframes rmd-progress-bar-vertical-reverse {
  0% {
    bottom: 100%;
    top: -35%; }
  60% {
    bottom: -90%;
    top: 100%; }
  100% {
    bottom: -90%;
    top: 100%; } }

@-webkit-keyframes rmd-progress-bar-vertical-reverse-short {
  0% {
    bottom: 100%;
    top: -200%; }
  40% {
    bottom: -8%;
    top: 107%; }
  100% {
    bottom: -8%;
    top: 107%; } }

@keyframes rmd-progress-bar-vertical-reverse-short {
  0% {
    bottom: 100%;
    top: -200%; }
  40% {
    bottom: -8%;
    top: 107%; }
  100% {
    bottom: -8%;
    top: 107%; } }

.rmd-circular-progress {
  height: 3rem;
  height: var(--rmd-progress-circular-size, 3rem);
  width: 3rem;
  width: var(--rmd-progress-circular-size, 3rem);
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  overflow: hidden; }
  .rmd-circular-progress--centered {
    display: flex;
    margin-left: auto;
    margin-right: auto; }
  .rmd-circular-progress__svg {
    height: inherit;
    width: inherit; }
    .rmd-circular-progress__svg--animate {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition: -webkit-transform 0.1s;
      transition: transform 0.1s;
      transition: transform 0.1s, -webkit-transform 0.1s; }
    .rmd-circular-progress__svg--indeterminate {
      -webkit-animation: rmd-progress-rotate 2.4s linear infinite;
              animation: rmd-progress-rotate 2.4s linear infinite; }
  .rmd-circular-progress__circle {
    stroke: #B92754;
    stroke: var(--rmd-progress-color, #B92754);
    stroke-width: 6;
    stroke-width: var(--rmd-progress-circular-width, 6);
    fill: none;
    stroke-dasharray: 187;
    stroke-linecap: round; }
    .rmd-circular-progress__circle--animate {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition: stroke-dashoffset 0.1s; }
    .rmd-circular-progress__circle--indeterminate {
      -webkit-animation: rmd-circular-progress-size 2.4s ease-in-out infinite;
              animation: rmd-circular-progress-size 2.4s ease-in-out infinite; }

@-webkit-keyframes rmd-progress-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  75% {
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@keyframes rmd-progress-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  75% {
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@-webkit-keyframes rmd-circular-progress-size {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75; }
  100% {
    stroke-dashoffset: 187; } }

@keyframes rmd-circular-progress-size {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75; }
  100% {
    stroke-dashoffset: 187; } }

:root {
  --rmd-sheet-touch-width: calc(100vw - 3.5rem);
  --rmd-sheet-static-width: 16rem;
  --rmd-sheet-touchable-max-height: calc(100% - 3.5rem); }

.rmd-sheet {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: var(--rmd-sheet-max-height);
  height: var(--rmd-sheet-height);
  width: var(--rmd-sheet-width);
  position: fixed;
  z-index: 5; }
  .rmd-sheet--top {
    --rmd-sheet-transform-offscreen: translate3d(0, -100%, 0);
    top: 0; }
  .rmd-sheet--right {
    right: 0;
    --rmd-sheet-transform-offscreen: translate3d(100%, 0, 0); }
    [dir='rtl'] .rmd-sheet--right {
      right: auto;
      left: 0;
      --rmd-sheet-transform-offscreen: translate3d(-100%, 0, 0); }
  .rmd-sheet--bottom {
    --rmd-sheet-transform-offscreen: translate3d(0, 100%, 0);
    bottom: 0; }
  .rmd-sheet--left {
    left: 0;
    --rmd-sheet-transform-offscreen: translate3d(-100%, 0, 0); }
    [dir='rtl'] .rmd-sheet--left {
      left: auto;
      right: 0;
      --rmd-sheet-transform-offscreen: translate3d(100%, 0, 0); }
  .rmd-sheet--raised {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    z-index: 30; }
  .rmd-sheet--horizontal {
    bottom: 0;
    top: 0; }
  .rmd-sheet--touch-width {
    --rmd-sheet-width: var(--rmd-sheet-touch-width, calc(100vw - 3.5rem)); }
  .rmd-sheet--static-width {
    --rmd-sheet-width: var(--rmd-sheet-static-width, 16rem); }
  .rmd-sheet--media-width {
    --rmd-sheet-width: var(--rmd-sheet-touch-width, calc(100vw - 3.5rem)); }
    @media screen and (min-width: 48em) {
      .rmd-sheet--media-width {
        --rmd-sheet-width: var(--rmd-sheet-static-width, 16rem); } }
  .rmd-sheet--vertical {
    left: 0;
    right: 0; }
  .rmd-sheet--viewport-height {
    --rmd-sheet-max-height: 100%; }
  .rmd-sheet--touchable-height {
    --rmd-sheet-max-height: var(--rmd-sheet-touchable-max-height, calc(100% - 3.5rem)); }
  .rmd-sheet--recommended-height {
    max-height: 50%;
    min-height: 3.5rem; }
  .rmd-sheet--offscreen {
    -webkit-transform: var(--rmd-sheet-transform-offscreen);
            transform: var(--rmd-sheet-transform-offscreen); }
  .rmd-sheet--hidden {
    box-shadow: none; }
  .rmd-sheet--visible {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .rmd-sheet--enter {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .rmd-sheet--exit {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }

.rmd-sheet-overlay {
  z-index: 30; }

.rmd-layout-transition--enter {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition: margin 0.2s; }

.rmd-layout-transition--exit {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition: margin 0.15s; }

.rmd-layout-navigation {
  z-index: 30; }
  .rmd-layout-navigation--floating {
    background-color: transparent;
    box-shadow: none; }
  .rmd-layout-navigation--header-offset {
    top: 3.5rem;
    top: var(--rmd-app-bar-height, 3.5rem); }

.rmd-layout-nav {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  height: 100%; }

.rmd-layout-tree {
  height: auto;
  overflow: visible; }

.rmd-layout-title--offset {
  margin-left: calc(16rem + 1rem);
  margin-left: calc(var(--rmd-sheet-static-width, 16rem) + 1rem); }
  [dir='rtl'] .rmd-layout-title--offset {
    margin-left: 0;
    margin-right: calc(16rem + 1rem);
    margin-right: calc(var(--rmd-sheet-static-width, 16rem) + 1rem); }

.rmd-layout-nav-toggle--offset {
  margin-right: calc(1.25rem + 16rem - 3rem);
  margin-right: calc(1.25rem + var(--rmd-layout-nav-width, 16rem) - var(--rmd-button-icon-size, 3rem)); }
  [dir='rtl'] .rmd-layout-nav-toggle--offset {
    margin-right: 0.25rem;
    margin-left: calc(1.25rem + 16rem - 3rem);
    margin-left: calc(1.25rem + var(--rmd-layout-nav-width, 16rem) - var(--rmd-button-icon-size, 3rem)); }

.rmd-layout-nav-header--bordered {
  border-color: rgba(0, 0, 0, 0.12);
  border-color: var(--rmd-divider-background-color, rgba(0, 0, 0, 0.12));
  border-width: 1px;
  border-width: var(--rmd-divider-size, 1px);
  border-bottom-width: 1px;
  border-bottom-width: var(--rmd-divider-size, 1px);
  border-bottom-style: solid; }

.rmd-layout-main {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  height: 100%; }
  .rmd-layout-main:focus {
    outline-style: none; }
  .rmd-layout-main::-moz-focus-inner {
    border: 0; }
  .rmd-utils--keyboard .rmd-layout-main {
    box-shadow: none;
    position: relative; }
    .rmd-utils--keyboard .rmd-layout-main::before {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: inherit;
      content: '';
      pointer-events: none;
      z-index: 0;
      box-shadow: inset 0 0 0 0.125rem #2196f3;
      opacity: 0;
      transition: opacity 0.15s; }
    .rmd-utils--keyboard .rmd-layout-main:focus::before {
      opacity: 1; }
    .rmd-utils--keyboard .rmd-layout-main::before {
      margin: inherit;
      position: fixed;
      z-index: 999; }
  .rmd-layout-main--header-offset {
    padding-top: 3.5rem;
    padding-top: var(--rmd-app-bar-height, 3.5rem); }
    .rmd-utils--keyboard .rmd-layout-main--header-offset::before {
      top: 3.5rem;
      top: var(--rmd-app-bar-height, 3.5rem); }
  .rmd-layout-main--nav-offset {
    margin-left: 16rem;
    margin-left: var(--rmd-layout-nav-width, 16rem); }
    [dir='rtl'] .rmd-layout-main--nav-offset {
      margin-right: 16rem;
      margin-right: var(--rmd-layout-nav-width, 16rem);
      margin-left: auto; }

:root {
  --rmd-states-focus-shadow: inset 0 0 0 0.125rem #2196f3;
  --rmd-states-pressed-color: rgba(0, 0, 0, 0.32);
  --rmd-states-ripple-background-color: rgba(0, 0, 0, 0.08);
  --rmd-states-light-hover-color: rgba(0, 0, 0, 0.08);
  --rmd-states-light-focus-color: rgba(0, 0, 0, 0.24);
  --rmd-states-light-pressed-color: rgba(0, 0, 0, 0.32);
  --rmd-states-light-selected-color: rgba(0, 0, 0, 0.16);
  --rmd-states-light-ripple-background-color: rgba(0, 0, 0, 0.08);
  --rmd-states-dark-hover-color: rgba(0, 0, 0, 0.04);
  --rmd-states-dark-focus-color: rgba(0, 0, 0, 0.12);
  --rmd-states-dark-pressed-color: rgba(0, 0, 0, 0.16);
  --rmd-states-dark-selected-color: rgba(0, 0, 0, 0.12);
  --rmd-states-dark-ripple-background-color: rgba(0, 0, 0, 0.08); }

.rmd-ripple-container {
  border-radius: inherit;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0; }

.rmd-ripple {
  background-color: rgba(0, 0, 0, 0.08);
  background-color: var(--rmd-states-ripple-background-color, rgba(0, 0, 0, 0.08));
  border-radius: 50%;
  position: absolute;
  -webkit-transform: scale(0);
          transform: scale(0); }
  .rmd-ripple--animating {
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.45s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.45s cubic-bezier(0, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
    transition: transform 0.45s cubic-bezier(0, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.45s cubic-bezier(0, 0, 0.2, 1); }
  .rmd-ripple--scaling {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .rmd-ripple--fading {
    opacity: 0; }

:root {
  --rmd-table-cell-color: var(--rmd-theme-text-primary-on-background, #212121);
  --rmd-table-cell-h-padding: 1rem;
  --rmd-table-cell-v-padding: 0.375rem;
  --rmd-table-cell-height: 3.25rem;
  --rmd-table-cell-dense-height: 2rem;
  --rmd-table-hover-color: rgba(0, 0, 0, 0.12);
  --rmd-table-selected-color: var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16));
  --rmd-table-sticky-header: 0;
  --rmd-table-sticky-cell: 0;
  --rmd-table-sticky-footer: 0; }

.rmd-table-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.rmd-table {
  border-collapse: collapse;
  max-width: 100%; }
  .rmd-table--dense {
    --rmd-table-cell-height: var(--rmd-table-cell-dense-height, 2rem); }
  .rmd-table--full-width {
    width: 100%; }

.rmd-thead {
  --rmd-table-cell-height: 3.5rem; }
  .rmd-table--dense .rmd-thead {
    --rmd-table-cell-height: 2.125rem; }

.rmd-table-cell {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  color: #212121;
  color: var(--rmd-table-cell-color, var(--rmd-theme-text-primary-on-background, #212121));
  height: 3.25rem;
  height: var(--rmd-table-cell-height, 3.25rem);
  padding-left: 1rem;
  padding-left: var(--rmd-table-cell-h-padding, 1rem);
  padding-right: 1rem;
  padding-right: var(--rmd-table-cell-h-padding, 1rem); }
  .rmd-table-cell--top {
    vertical-align: top; }
  .rmd-table-cell--bottom {
    vertical-align: bottom; }
  .rmd-table-cell--vertical {
    padding-top: 0.375rem;
    padding-top: var(--rmd-table-cell-v-padding, 0.375rem);
    padding-bottom: 0.375rem;
    padding-bottom: var(--rmd-table-cell-v-padding, 0.375rem); }
  .rmd-table-cell--header {
    font-weight: 500;
    letter-spacing: 0.00714em;
    line-height: 1.375rem;
    text-align: left; }
    [dir='rtl'] .rmd-table-cell--header {
      text-align: right; }
  .rmd-table-cell--center {
    text-align: center; }
  .rmd-table-cell--right {
    text-align: right; }
    [dir='rtl'] .rmd-table-cell--right {
      text-align: left; }
  .rmd-table-cell--grow {
    width: 100%; }
  .rmd-table-cell--no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .rmd-table-cell--no-padding {
    padding: 0; }
  .rmd-table-cell--checkbox {
    --rmd-table-cell-h-padding: 0.5rem; }
  .rmd-table-cell--sticky {
    background-color: #fafafa;
    background-color: var(--rmd-theme-background, #fafafa);
    border: inherit;
    position: -webkit-sticky;
    position: sticky;
    will-change: transform;
    z-index: 2; }
    .rmd-utils--mouse .rmd-table-cell--sticky::after {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition: background-color 0.15s; }
    .rmd-table-cell--sticky::after {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      border: inherit;
      content: '';
      pointer-events: none; }
  .rmd-table-cell--sticky-header {
    top: 0;
    top: var(--rmd-table-sticky-header, 0); }
  .rmd-table-cell--sticky-cell {
    left: 0;
    left: var(--rmd-table-sticky-cell, 0); }
    [dir='rtl'] .rmd-table-cell--sticky-cell {
      right: 0;
      right: var(--rmd-table-sticky-cell, 0);
      left: auto; }
  .rmd-table-cell--sticky-above {
    z-index: 3; }
  .rmd-table-cell--sticky-footer {
    bottom: 0;
    bottom: var(--rmd-table-sticky-footer, 0); }
  .rmd-table-cell--padded {
    padding-bottom: 0.375rem;
    padding-bottom: var(--rmd-table-cell-v-padding, 0.375rem);
    padding-top: 0.375rem;
    padding-top: var(--rmd-table-cell-v-padding, 0.375rem); }
  .rmd-table-cell--no-padding {
    padding: 0; }
  .rmd-table-cell__child {
    padding-left: 1rem;
    padding-left: var(--rmd-table-cell-h-padding, 1rem);
    padding-right: 1rem;
    padding-right: var(--rmd-table-cell-h-padding, 1rem);
    align-items: center;
    color: inherit;
    font: inherit;
    height: 100%;
    width: 100%; }

.rmd-tr--selected {
  background-color: rgba(0, 0, 0, 0.16);
  background-color: var(--rmd-table-selected-color, var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16))); }
  .rmd-tr--selected .rmd-table-cell--sticky-cell::after {
    background-color: rgba(0, 0, 0, 0.16);
    background-color: var(--rmd-table-selected-color, var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16))); }

.rmd-tr--clickable:hover {
  cursor: pointer; }

.rmd-tr--bordered {
  border-color: rgba(0, 0, 0, 0.12);
  border-color: var(--rmd-divider-background-color, rgba(0, 0, 0, 0.12));
  border-width: 1px;
  border-width: var(--rmd-divider-size, 1px);
  border-bottom-width: 1px;
  border-bottom-width: var(--rmd-divider-size, 1px);
  border-bottom-style: solid; }
  .rmd-tr--bordered:last-child {
    border-bottom-width: 0; }

.rmd-utils--mouse .rmd-tr--hoverable {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.15s; }
  .rmd-utils--mouse .rmd-tr--hoverable:hover {
    background-color: rgba(0, 0, 0, 0.12);
    background-color: var(--rmd-table-hover-color, rgba(0, 0, 0, 0.12)); }
    .rmd-utils--mouse .rmd-tr--hoverable:hover .rmd-table-cell--sticky-cell::after {
      background-color: rgba(0, 0, 0, 0.12);
      background-color: var(--rmd-table-hover-color, rgba(0, 0, 0, 0.12)); }

.rmd-caption {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

:root {
  --rmd-tabs-active: #212121;
  --rmd-tabs-inactive: #757575;
  --rmd-tabs-disabled: #9e9e9e;
  --rmd-tabs-indicator-color: var(--rmd-theme-primary, #B92754); }

.rmd-tabs {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%; }
  .rmd-tabs:focus {
    outline-style: none; }
  .rmd-tabs::-moz-focus-inner {
    border: 0; }
  .rmd-tabs::-webkit-scrollbar {
    height: 0;
    width: 0; }
  .rmd-tabs::after {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    background-color: #B92754;
    background-color: var(--rmd-tabs-indicator-color, var(--rmd-theme-primary, #B92754));
    bottom: 0;
    content: '';
    height: 0.125rem;
    left: 0;
    max-width: 5.625rem;
    max-width: var(--rmd-tab-width, 5.625rem);
    pointer-events: none;
    position: absolute;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform: translateX(var(--rmd-tab-offset, 0));
            transform: translateX(var(--rmd-tab-offset, 0));
    width: 100%;
    z-index: 1; }
  .rmd-tabs--animate::after {
    transition: max-width 0.15s, -webkit-transform 0.15s;
    transition: transform 0.15s, max-width 0.15s;
    transition: transform 0.15s, max-width 0.15s, -webkit-transform 0.15s; }
  .rmd-tabs--left {
    justify-content: flex-start; }
  .rmd-tabs--center {
    justify-content: center; }
  .rmd-tabs--right {
    justify-content: flex-end; }
  .rmd-tabs--padded {
    padding-left: 3.25rem; }
    [dir='rtl'] .rmd-tabs--padded {
      padding-left: 0;
      padding-right: 3.25rem; }

.rmd-tab {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #757575;
  color: var(--rmd-tabs-inactive, #757575);
  align-items: center;
  background-color: transparent;
  border-width: 0;
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 0;
  height: 3rem;
  justify-content: center;
  max-width: 20rem;
  min-width: 5.625rem;
  padding: 0 1rem;
  position: relative;
  transition: color 0.15s; }
  .rmd-tab:focus {
    outline-style: none; }
  .rmd-tab::-moz-focus-inner {
    border: 0; }
  .rmd-utils--keyboard .rmd-tab:focus::before {
    box-shadow: inset 0 0 0 0.125rem #2196f3;
    box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
  .rmd-tab::before {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: inherit;
    content: '';
    pointer-events: none;
    z-index: 0;
    background-color: transparent;
    background-color: var(--rmd-states-background-color, transparent);
    transition: background-color 0.15s; }
  .rmd-tab:disabled, .rmd-tab[aria-disabled='true'] {
    --rmd-states-hover-color: transparent; }
  .rmd-tab:not(:disabled):not([aria-disabled='true']):hover {
    cursor: pointer; }
  .rmd-tab:hover {
    --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--keyboard .rmd-tab:focus {
    --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
    .rmd-utils--keyboard .rmd-tab:focus:hover {
      --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
  .rmd-utils--touch .rmd-tab:focus, .rmd-utils--touch .rmd-tab:hover {
    --rmd-states-background-color: transparent; }
  .rmd-tab.rmd-states--pressed {
    --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
    .rmd-utils--keyboard .rmd-tab.rmd-states--pressed {
      --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
  .rmd-tab:focus {
    outline-style: none; }
  .rmd-tab::-moz-focus-inner {
    border: 0; }
  .rmd-tab--stacked {
    flex-direction: column;
    height: 4.5rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem; }
  .rmd-tab--active {
    color: #212121;
    color: var(--rmd-tabs-active, #212121);
    --rmd-icon-color: var(--rmd-tabs-active, #212121); }
  .rmd-tab--disabled {
    color: #9e9e9e;
    color: var(--rmd-tabs-disabled, #9e9e9e);
    --rmd-icon-color: var(--rmd-tabs-disabled, #9e9e9e); }

.rmd-tab-panels {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  overflow-x: hidden; }
  .rmd-tab-panels--slide-left {
    --p1-start: 100%;
    --p2-start: -100%;
    --p1-end: 0;
    --p2-end: -200%; }
  .rmd-tab-panels--slide-left-persistent {
    --p1-start: 0;
    --p2-start: 0;
    --p1-end: -100%;
    --p2-end: -100%; }
  .rmd-tab-panels--slide-right {
    --p1-start: -100%;
    --p2-start: -100%;
    --p1-end: 0;
    --p2-end: 0; }

.rmd-tab-panel {
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  will-change: transform; }
  .rmd-tab-panel--animate {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0.15s;
    transition: transform 0.15s;
    transition: transform 0.15s, -webkit-transform 0.15s; }
  .rmd-tab-panel--enter {
    -webkit-transform: translate3d(var(--p1-start), 0, 0);
            transform: translate3d(var(--p1-start), 0, 0); }
  .rmd-tab-panel--enter-active {
    -webkit-transform: translate3d(var(--p1-end), 0, 0);
            transform: translate3d(var(--p1-end), 0, 0); }
  .rmd-tab-panel--exit {
    -webkit-transform: translate3d(var(--p2-start), 0, 0);
            transform: translate3d(var(--p2-start), 0, 0); }
  .rmd-tab-panel--exit-active {
    -webkit-transform: translate3d(var(--p2-end), 0, 0);
            transform: translate3d(var(--p2-end), 0, 0); }

:root {
  --rmd-theme-background: #fafafa;
  --rmd-theme-primary: #B92754;
  --rmd-theme-on-primary: #000;
  --rmd-theme-secondary: #000;
  --rmd-theme-on-secondary: #000;
  --rmd-theme-surface: #fff;
  --rmd-theme-on-surface: #000;
  --rmd-theme-warning: #ff6e40;
  --rmd-theme-on-warning: #fff;
  --rmd-theme-error: #f44336;
  --rmd-theme-on-error: #000;
  --rmd-theme-success: #00c853;
  --rmd-theme-on-success: #fff;
  --rmd-theme-text-primary-on-background: #212121;
  --rmd-theme-text-secondary-on-background: #757575;
  --rmd-theme-text-hint-on-background: #a8a8a8;
  --rmd-theme-text-disabled-on-background: #9e9e9e;
  --rmd-theme-text-icon-on-background: #757575;
  --rmd-theme-light-background: #fafafa;
  --rmd-theme-light-surface: #fff;
  --rmd-theme-dark-background: #303030;
  --rmd-theme-dark-surface: #424242;
  --rmd-theme-text-primary-on-light: #212121;
  --rmd-theme-text-secondary-on-light: #757575;
  --rmd-theme-text-hint-on-light: #a8a8a8;
  --rmd-theme-text-disabled-on-light: #9e9e9e;
  --rmd-theme-text-icon-on-light: #757575;
  --rmd-theme-text-primary-on-dark: #d9d9d9;
  --rmd-theme-text-secondary-on-dark: #b3b3b3;
  --rmd-theme-text-hint-on-dark: gray;
  --rmd-theme-text-disabled-on-dark: gray;
  --rmd-theme-text-icon-on-dark: #b3b3b3; }

:root {
  --rmd-tooltip-background-color: #616161;
  --rmd-tooltip-color: var(--rmd-theme-text-primary-on-dark, #d9d9d9);
  --rmd-tooltip-transition-distance: 0.5rem;
  --rmd-tooltip-z-index: 50;
  --rmd-tooltip-spacing: 1.5rem;
  --rmd-tooltip-min-height: 2rem;
  --rmd-tooltip-max-width: 15rem;
  --rmd-tooltip-font-size: 1rem;
  --rmd-tooltip-line-height: 1.5rem;
  --rmd-tooltip-horizontal-padding: 1rem;
  --rmd-tooltip-vertical-padding: 0.5625rem;
  --rmd-tooltip-dense-spacing: 0.875rem;
  --rmd-tooltip-dense-min-height: 1.375rem;
  --rmd-tooltip-dense-font-size: 0.625rem;
  --rmd-tooltip-dense-line-height: 0.825rem;
  --rmd-tooltip-dense-horizontal-padding: 0.5rem;
  --rmd-tooltip-dense-vertical-padding: 0.375rem; }

.rmd-tooltip {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.03571em;
  background-color: #616161;
  background-color: var(--rmd-tooltip-background-color, #616161);
  color: #d9d9d9;
  color: var(--rmd-tooltip-color, var(--rmd-theme-text-primary-on-dark, #d9d9d9));
  font-size: 1rem;
  font-size: var(--rmd-tooltip-font-size, 1rem);
  line-height: 1.5rem;
  line-height: var(--rmd-tooltip-line-height, 1.5rem);
  min-height: 2rem;
  min-height: var(--rmd-tooltip-min-height, 2rem);
  max-width: 15rem;
  max-width: var(--rmd-tooltip-max-width, 15rem);
  padding-left: 1rem;
  padding-left: var(--rmd-tooltip-horizontal-padding, 1rem);
  padding-right: 1rem;
  padding-right: var(--rmd-tooltip-horizontal-padding, 1rem);
  z-index: 50;
  z-index: var(--rmd-tooltip-z-index, 50);
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  text-transform: none;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap; }
  .rmd-tooltip--line-wrap {
    padding-bottom: 0.5625rem;
    padding-bottom: var(--rmd-tooltip-vertical-padding, 0.5625rem);
    padding-top: 0.5625rem;
    padding-top: var(--rmd-tooltip-vertical-padding, 0.5625rem);
    white-space: normal; }
  .rmd-tooltip--dense {
    --rmd-tooltip-font-size: var(--rmd-tooltip-dense-font-size, 0.625rem);
    --rmd-tooltip-line-height: var(--rmd-tooltip-dense-line-height, 0.825rem);
    --rmd-tooltip-min-height: var(--rmd-tooltip-dense-min-height, 1.375rem);
    --rmd-tooltip-horizontal-padding: var(--rmd-tooltip-dense-horizontal-padding, 0.5rem);
    --rmd-tooltip-vertical-padding: var(--rmd-tooltip-dense-vertical-padding, 0.375rem);
    --rmd-tooltip-spacing: var(--rmd-tooltip-dense-spacing, 0.875rem); }
  .rmd-tooltip--above {
    -webkit-transform: translateY(calc(-1 * 0.5rem));
            transform: translateY(calc(-1 * 0.5rem));
    -webkit-transform: translateY(calc(-1 * var(--rmd-tooltip-transition-distance, 0.5rem)));
            transform: translateY(calc(-1 * var(--rmd-tooltip-transition-distance, 0.5rem))); }
  .rmd-tooltip--below {
    -webkit-transform: translateY(0.5rem);
            transform: translateY(0.5rem);
    -webkit-transform: translateY(var(--rmd-tooltip-transition-distance, 0.5rem));
            transform: translateY(var(--rmd-tooltip-transition-distance, 0.5rem)); }
  .rmd-tooltip--left {
    -webkit-transform: translateX(calc(-1 * 0.5rem));
            transform: translateX(calc(-1 * 0.5rem));
    -webkit-transform: translateX(calc(-1 * var(--rmd-tooltip-transition-distance, 0.5rem)));
            transform: translateX(calc(-1 * var(--rmd-tooltip-transition-distance, 0.5rem))); }
  .rmd-tooltip--right {
    -webkit-transform: translateX(0.5rem);
            transform: translateX(0.5rem);
    -webkit-transform: translateX(var(--rmd-tooltip-transition-distance, 0.5rem));
            transform: translateX(var(--rmd-tooltip-transition-distance, 0.5rem)); }
  .rmd-tooltip--visible {
    opacity: 1;
    -webkit-transform: none;
            transform: none; }
  .rmd-tooltip--enter {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.15s, -webkit-transform 0.3s;
    transition: opacity 0.15s, transform 0.3s;
    transition: opacity 0.15s, transform 0.3s, -webkit-transform 0.3s; }
  .rmd-tooltip--exit {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 0.15s; }
  .rmd-tooltip--exit-active {
    opacity: 0; }

.rmd-collapse {
  transition-property: max-height, padding-bottom, padding-top;
  will-change: max-height, padding-bottom, padding-top; }
  .rmd-collapse--no-overflow {
    overflow: hidden; }
  .rmd-collapse--enter {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  .rmd-collapse--leave {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.rmd-cross-fade {
  opacity: 0;
  -webkit-transform: translateY(-1rem);
          transform: translateY(-1rem); }
  .rmd-cross-fade--active {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition-duration: 0.3s;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform; }

.rmd-transition--scale-enter {
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0); }

.rmd-transition--scale-enter-active {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s; }

.rmd-transition--scale-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.rmd-transition--scale-exit-active {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: transform 0.15s, opacity 0.15s;
  transition: transform 0.15s, opacity 0.15s, -webkit-transform 0.15s; }

.rmd-transition--scale-y-enter {
  opacity: 0;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; }

.rmd-transition--scale-y-enter-active {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s; }

.rmd-transition--scale-y-exit {
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; }

.rmd-transition--scale-y-exit-active {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: transform 0.15s, opacity 0.15s;
  transition: transform 0.15s, opacity 0.15s, -webkit-transform 0.15s; }

:root {
  --rmd-tree-incrementor: 1.5rem;
  --rmd-tree-base-padding: 4.5rem; }

.rmd-tree {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%; }
  .rmd-tree [aria-level="2"].rmd-tree-item__content, .rmd-tree [aria-level="2"] > .rmd-tree-item__content {
    padding-left: 4.5rem; }
    [dir='rtl'] .rmd-tree [aria-level="2"].rmd-tree-item__content, [dir='rtl'] .rmd-tree [aria-level="2"] > .rmd-tree-item__content {
      padding-left: 1rem;
      padding-right: 4.5rem; }
  .rmd-tree [aria-level="3"].rmd-tree-item__content, .rmd-tree [aria-level="3"] > .rmd-tree-item__content {
    padding-left: 6rem; }
    [dir='rtl'] .rmd-tree [aria-level="3"].rmd-tree-item__content, [dir='rtl'] .rmd-tree [aria-level="3"] > .rmd-tree-item__content {
      padding-left: 1rem;
      padding-right: 6rem; }
  .rmd-tree:focus {
    outline-style: none; }
  .rmd-tree::-moz-focus-inner {
    border: 0; }

.rmd-tree-item {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none; }
  .rmd-tree-item:focus {
    outline-style: none; }
  .rmd-tree-item::-moz-focus-inner {
    border: 0; }
  .rmd-tree-item__content {
    min-height: 3rem;
    min-height: var(--rmd-list-item-height, 3rem);
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem;
    padding: var(--rmd-list-item-vertical-padding, 0.5rem) var(--rmd-list-item-horizontal-padding, 1rem);
    position: relative; }
    .rmd-tree-item__content--clickable {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
      .rmd-tree-item__content--clickable:focus {
        outline-style: none; }
      .rmd-tree-item__content--clickable::-moz-focus-inner {
        border: 0; }
      .rmd-tree-item__content--clickable:focus {
        outline-style: none; }
      .rmd-tree-item__content--clickable::-moz-focus-inner {
        border: 0; }
      .rmd-utils--keyboard .rmd-tree-item__content--clickable:focus::before {
        box-shadow: inset 0 0 0 0.125rem #2196f3;
        box-shadow: var(--rmd-states-focus-shadow, inset 0 0 0 0.125rem #2196f3); }
      .rmd-tree-item__content--clickable::before {
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: inherit;
        content: '';
        pointer-events: none;
        z-index: 0;
        background-color: transparent;
        background-color: var(--rmd-states-background-color, transparent);
        transition: background-color 0.15s; }
      .rmd-tree-item__content--clickable:disabled, .rmd-tree-item__content--clickable[aria-disabled='true'] {
        --rmd-states-hover-color: transparent; }
      .rmd-tree-item__content--clickable:not(:disabled):not([aria-disabled='true']):hover {
        cursor: pointer; }
      .rmd-tree-item__content--clickable:hover {
        --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
      .rmd-utils--keyboard .rmd-tree-item__content--clickable:focus {
        --rmd-states-background-color: var(--rmd-states-focus-color, rgba(0, 0, 0, 0.24)); }
        .rmd-utils--keyboard .rmd-tree-item__content--clickable:focus:hover {
          --rmd-states-background-color: var(--rmd-states-hover-color, rgba(0, 0, 0, 0.08)); }
      .rmd-utils--touch .rmd-tree-item__content--clickable:focus, .rmd-utils--touch .rmd-tree-item__content--clickable:hover {
        --rmd-states-background-color: transparent; }
      .rmd-tree-item__content--clickable.rmd-states--pressed {
        --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
        .rmd-utils--keyboard .rmd-tree-item__content--clickable.rmd-states--pressed {
          --rmd-states-background-color: var(--rmd-states-pressed-color, rgba(0, 0, 0, 0.32)); }
    .rmd-tree-item__content[aria-disabled] {
      color: #9e9e9e;
      color: var(--rmd-theme-text-disabled-on-background, #9e9e9e); }
    .rmd-tree-item__content--link {
      color: inherit;
      text-decoration: none; }
    .rmd-tree-item__content--medium {
      --rmd-list-item-height: var(--rmd-list-item-medium-height, 3.5rem); }
    .rmd-tree-item__content--large {
      --rmd-list-item-height: var(--rmd-list-item-large-height, 4rem); }
    .rmd-tree-item__content--extra-large {
      --rmd-list-item-height: var(--rmd-list-item-extra-large-height, 4.5rem); }
    .rmd-tree-item__content--three-lines {
      --rmd-list-item-height: var(--rmd-list-item-three-line-height, 5.5rem); }
      .rmd-tree-item__content--three-lines .rmd-list-item__text--secondary {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        max-height: 3rem;
        max-height: var(--rmd-list-item-secondary-three-line-height, 3rem);
        line-height: 1.42857;
        white-space: normal; }
    .rmd-tree-item__content--dense {
      --rmd-list-item-height: var(--rmd-list-dense-item-height, 2.5rem);
      --rmd-list-item-medium-height: var(--rmd-list-dense-item-medium-height, 3rem);
      --rmd-list-item-large-height: var(--rmd-list-dense-item-large-height, 3.5rem);
      --rmd-list-item-extra-large-height: var(--rmd-list-dense-item-extra-large-height, 4rem);
      --rmd-list-item-three-line-height: var(--rmd-list-dense-item-three-line-height, 5rem);
      --rmd-list-item-secondary-three-line-height: var(--rmd-list-dense-item-secondary-three-line-height, 2.25rem); }
    .rmd-tree-item__content__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      flex-grow: 1;
      z-index: 1; }
      [dir='rtl'] .rmd-tree-item__content__text {
        margin-left: auto; }
      .rmd-tree-item__content__text--secondary {
        color: #757575;
        color: var(--rmd-theme-text-secondary-on-background, #757575); }
    .rmd-tree-item__content__addon {
      flex-shrink: 0; }
      .rmd-tree-item__content__addon--top {
        align-self: flex-start; }
      .rmd-tree-item__content__addon--bottom {
        align-self: flex-end; }
      .rmd-tree-item__content__addon--before {
        --rmd-icon-text-spacing: calc(var(--rmd-list-text-keyline, 4.5rem) - var(--rmd-list-item-horizontal-padding, 1rem) - var(--rmd-icon-size, 1.5rem)); }
      .rmd-tree-item__content__addon--avatar-before {
        --rmd-icon-text-spacing: calc(var(--rmd-list-text-keyline, 4.5rem) - var(--rmd-list-item-horizontal-padding, 1rem) - var(--rmd-avatar-size, 2.5rem)); }
      .rmd-tree-item__content__addon--media {
        --rmd-icon-text-spacing: var(--rmd-list-media-spacing, 1rem);
        width: 3.5rem;
        width: var(--rmd-list-media-size, 3.5rem); }
      .rmd-tree-item__content__addon--media-large {
        --rmd-list-media-size: var(--rmd-list-media-large-size, 6.25rem); }
    .rmd-tree-item__content--selected {
      --rmd-states-background-color: var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16)); }
      .rmd-utils--touch .rmd-tree-item__content--selected:hover, .rmd-utils--touch .rmd-tree-item__content--selected:focus {
        --rmd-states-background-color: var(--rmd-states-selected-color, rgba(0, 0, 0, 0.16)); }
    .rmd-utils--keyboard .rmd-tree-item__content--focused {
      box-shadow: inset 0 0 0 2px #2196f3; }

.rmd-tree-group {
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding-bottom: 0;
  padding-top: 0; }

.rmd-tree-item__rotator-icon {
  --rmd-icon-rotate-to: rotate(90deg); }

:root {
  --rmd-typography-line-width: 40em;
  --rmd-typography-mobile-line-width: 17em;
  --rmd-typography-desktop-line-width: 40em; }

.rmd-typography {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }
  .rmd-typography--headline-1 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 300;
    letter-spacing: -0.01562em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--headline-2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 3.75rem;
    line-height: 3.75rem;
    font-weight: 300;
    letter-spacing: -0.00833em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--headline-3 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 3rem;
    line-height: 3.125rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--headline-4 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 2.125rem;
    line-height: 2.5rem;
    font-weight: 400;
    letter-spacing: 0.00735em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--headline-5 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--headline-6 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--subtitle-1 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--subtitle-2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--body-1 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.03571em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--body-2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--caption {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-decoration: inherit;
    text-transform: inherit; }
  .rmd-typography--button {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 2.25rem;
    font-weight: 500;
    letter-spacing: 0.08929em;
    text-decoration: none;
    text-transform: uppercase; }
  .rmd-typography--overline {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.16667em;
    text-decoration: none;
    text-transform: uppercase; }
  .rmd-typography--light {
    font-weight: 300; }
  .rmd-typography--regular {
    font-weight: 400; }
  .rmd-typography--medium {
    font-weight: 500; }
  .rmd-typography--bold {
    font-weight: 700; }
  .rmd-typography--normal {
    font-style: normal; }
  .rmd-typography--italic {
    font-style: italic; }
  .rmd-typography--oblique {
    font-style: oblique; }
  .rmd-typography--secondary {
    color: #757575;
    color: var(--rmd-theme-text-secondary-on-background, #757575); }
  .rmd-typography--hint {
    color: #a8a8a8;
    color: var(--rmd-theme-text-hint-on-background, #a8a8a8); }
  .rmd-typography--theme-primary {
    color: #B92754;
    color: var(--rmd-theme-primary, #B92754); }
  .rmd-typography--theme-secondary {
    color: #000;
    color: var(--rmd-theme-secondary, #000); }
  .rmd-typography--theme-warning {
    color: #ff6e40;
    color: var(--rmd-theme-warning, #ff6e40); }
  .rmd-typography--theme-error {
    color: #f44336;
    color: var(--rmd-theme-error, #f44336); }
  .rmd-typography--left {
    text-align: left; }
  .rmd-typography--center {
    text-align: center; }
  .rmd-typography--right {
    text-align: right; }
  .rmd-typography--underline {
    text-decoration: underline; }
  .rmd-typography--overlineoverline-decoration {
    text-decoration: overline; }
  .rmd-typography--line-through {
    text-decoration: line-through; }
  .rmd-typography--capitalize {
    text-transform: capitalize; }
  .rmd-typography--uppercase {
    text-transform: uppercase; }
  .rmd-typography--lowercase {
    text-transform: lowercase; }
  .rmd-typography--no-margin {
    margin: 0; }
  .rmd-typography--no-margin-top {
    margin-top: 0; }
  .rmd-typography--no-margin-bottom {
    margin-bottom: 0; }

.rmd-text-container {
  max-width: 40em;
  max-width: var(--rmd-typography-line-width, 40em);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }
  @media (max-width: 37.5rem) {
    .rmd-text-container--auto {
      --rmd-typography-line-width: var(--rmd-typography-mobile-line-width, 17em); } }
  .rmd-text-container--mobile {
    --rmd-typography-line-width: var(--rmd-typography-mobile-line-width, 17em); }
  .rmd-text-container--desktop {
    --rmd-typography-line-width: var(--rmd-typography-desktop-line-width, 40em); }

.rmd-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }
  .rmd-sr-only--focusable:active, .rmd-sr-only--focusable:focus {
    clip: auto;
    -webkit-clip-path: none;
            clip-path: none;
    height: auto;
    margin: auto;
    overflow: visible;
    white-space: normal;
    width: auto; }

html *:not(.material-icons), body *:not(.material-icons) {
  font-family: "Inter", sans-serif !important; }

.rmd-button--primary {
  color: #fff !important; }

.rmd-button--text {
  text-transform: none;
  font-weight: 700; }

.rmd-table {
  width: 100%; }

.rmd-floating-label {
  pointer-events: none; }

.rmd-table-cell--header {
  font-weight: 700; }

.rmd-button--secondary {
  color: #fff; }

.amped-form__grid {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 16px; }
  @media all and (max-width: 768px) {
    .amped-form__grid {
      grid-template-columns: minmax(100px, 1fr); } }
  .amped-form__grid:last-child {
    margin-bottom: 0; }

.amped-form > div:nth-last-child(2) {
  margin-bottom: 0 !important; }

