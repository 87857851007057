/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.rp-textarea__label {
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  display: block; }

.rp-textarea .rdw-editor-toolbar {
  border-color: #E0E0E0; }

.rp-textarea .rdw-editor-main {
  border: solid 1px #E0E0E0;
  margin: 0;
  padding: 0 16px;
  min-height: 250px; }

.rp-textarea .rdw-option-wrapper {
  transition: background-color 0.3s ease-out;
  backface-visibility: hidden;
  border: none !important;
  box-shadow: none;
  height: 25px; }
  .rp-textarea .rdw-option-wrapper:hover {
    background-color: #eee; }
