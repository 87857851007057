/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.sidebar {
  background-color: #101924;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0; }
  .sidebar__item {
    transition: color 0.3s ease-out;
    backface-visibility: hidden;
    height: 40px;
    padding: 0 24px;
    color: #6e82a5;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-weight: 700; }
    .sidebar__item--align-center {
      display: flex;
      align-items: center;
      line-height: 24px; }
    .sidebar__item span {
      transform: translateY(2px); }
    .sidebar__item svg {
      margin-right: 8px; }
      .sidebar__item svg path {
        transition: fill 0.3s ease-out;
        backface-visibility: hidden;
        fill: #6e82a5; }
    .sidebar__item--active, .sidebar__item:hover {
      color: #fff; }
      .sidebar__item--active svg path, .sidebar__item:hover svg path {
        fill: #fff; }
  .sidebar__list__children {
    transition: max-height 0.3s ease-out;
    backface-visibility: hidden;
    padding-left: 32px;
    max-height: 0;
    overflow: hidden; }
  .sidebar__list--has-children {
    position: relative; }
    .sidebar__list--has-children:before {
      transition: transform 0.3s ease-out;
      backface-visibility: hidden;
      content: '';
      position: absolute;
      top: 8px;
      right: 24px;
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' class='rmd-icon rmd-icon--svg' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%236e82a5' d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E");
      width: 24px;
      height: 24px;
      background-size: contain; }
    .sidebar__list--has-children.sidebar__list--active:before {
      transform: rotate(-90deg);
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' class='rmd-icon rmd-icon--svg' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E"); }
  .sidebar__list--active .sidebar__list__children {
    max-height: 300px; }
  .sidebar__logo {
    width: 100%;
    height: 85px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    text-align: center;
    padding: 16px; }
    .sidebar__logo img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 200px; }
      .sidebar__logo img.left-align {
        width: auto; }
      .sidebar__logo img.color {
        background-color: white; }
  .sidebar__nav {
    margin-top: 16px;
    flex: 1; }
  .sidebar__user-info {
    border-top: solid 1px rgba(255, 255, 255, 0.3);
    height: 85px;
    padding: 16px;
    display: flex;
    align-items: center; }
    .sidebar__user-info .user-avatar__name {
      color: #6e82a5; }
  .sidebar__user-profile {
    display: flex;
    align-items: center; }
