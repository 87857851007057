/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-list-item {
  padding: 16px 16px 0;
  max-width: 260px;
  background-color: #fff;
  text-decoration: none; }
  .product-list-item__actions {
    margin-bottom: 16px; }
  .product-list-item__active-pill {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.289412px;
    text-transform: uppercase;
    padding: 4px 16px;
    border-radius: 25px;
    background-color: #BEECAE;
    width: fit-content;
    color: #000; }
    .product-list-item__active-pill--inactive {
      opacity: 0.5; }
  .product-list-item--add-new .product-list-item__title {
    text-align: center; }
  .product-list-item__image {
    margin-bottom: 16px;
    position: relative;
    max-height: 205px;
    overflow: hidden; }
    .product-list-item__image img {
      width: 100%;
      height: 100%;
      max-height: 205px;
      object-fit: cover;
      object-position: center; }
  .product-list-item__price {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.289412px;
    color: #FC5858;
    display: flex; }
    .product-list-item__price__regular--on-sale {
      margin-left: 8px;
      color: #979797;
      text-decoration: line-through; }
  .product-list-item__title {
    font-size: 16px;
    line-height: 19px;
    color: #141414;
    margin-bottom: 16px;
    font-weight: 400; }
