/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.user-profile-page .amped-graph-query__inner {
  margin-top: 24px; }
