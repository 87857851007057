/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.attribute-selector__label {
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  display: block; }

.attribute-selector__results {
  margin-bottom: 16px; }
  .attribute-selector__results > button {
    margin-right: 16px; }
