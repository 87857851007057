/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.product-variant__divider {
  height: 1px;
  background-color: #E0E0E0;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%; }

.product-variant__item {
  margin-bottom: 24px; }

.product-variant .amped-form {
  display: flex;
  align-items: flex-end; }
  .product-variant .amped-form .amped-form__grid {
    flex: 1;
    margin-right: 16px; }

.product-variant .form-builder__actions .rmd-button {
  height: 48px; }
