@import "../../styles/variables";

.form-header {
  padding-top: $gutter-5;
  padding-bottom: $gutter-3;
  background-color: #F4F4F4;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-weight: 700;
  margin-bottom: $gutter-1;
  width: calc(100% + #{$gutter-6});
  transform: translateX(-$gutter-3);
  margin-top: $gutter-2;

  &--top-offset {
    padding-top: $gutter-3;
    transform: translate(-$gutter-3, -$gutter-3);
    margin-top: 0;
    margin-bottom: 0;
  }
}
