/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.auth {
  width: 480px;
  background: #fff;
  padding: 40px; }
  .auth--register {
    width: 655px; }
  .auth__actions {
    margin-top: 16px;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end; }
    .auth__actions button {
      width: 100%; }
  .auth__auth-urls {
    display: flex;
    justify-content: center; }
  .auth__description {
    margin-bottom: 32px; }
  .auth__error {
    margin-bottom: 16px;
    color: #cc0000; }
  .auth__field {
    margin-bottom: 16px; }
  .auth__form__content p {
    font-size: 14px;
    line-height: 23px; }
  .auth__form__logo {
    width: 50%;
    margin-bottom: 24px; }
    .auth__form__logo img {
      width: 100%; }
  .auth__or {
    width: 200px;
    position: relative;
    margin: 0 auto;
    height: 20px;
    margin-bottom: 16px; }
    .auth__or:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: #ddd; }
    .auth__or div {
      background-color: #f5f6fa;
      color: #ccc;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 8px;
      font-size: 14px; }
  .auth__register {
    margin-bottom: 24px;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center; }
    .auth__register a {
      color: #000000;
      font-weight: 700;
      padding-right: 8px;
      margin-right: 8px;
      display: block;
      position: relative;
      text-decoration: none; }
      .auth__register a:last-child {
        padding-right: 0;
        margin-right: 0; }
        .auth__register a:last-child:before {
          display: none; }
      .auth__register a:before {
        content: '';
        position: absolute;
        height: 50%;
        width: 1px;
        background-color: #101924;
        top: 25%;
        right: 0; }
      .auth__register a:hover {
        text-decoration: underline; }
  .auth .md-card-title {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0; }
  .auth .md-card-text {
    padding: 0 !important; }
  .auth .rmd-text-field {
    background-color: #fff; }
  .auth .form-builder__actions {
    margin-top: 16px;
    margin-bottom: 16px; }
    .auth .form-builder__actions button {
      width: 100%; }

.google-btn {
  width: 200px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  display: block;
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none; }
  .google-btn .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff; }
  .google-btn .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px; }
  .google-btn .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    transform: translateY(1px); }
  .google-btn:hover {
    box-shadow: 0 0 6px #4285f4; }
  .google-btn:active {
    background: #1669F2; }
