/**
  Will print a media query based on what is passed as breakpoint.
  $breakpoint
    'x-large'
    'large'
    'medium'
    'small'
    Any size specific measurement (240px, 20em, etc)

   $use-min-width
    (true) use min-width as the media query
    (false) use max-width as the media query

   Usage

   @include break-on('medium') {
    ...styles
   }
 */
.loader {
  transition: opacity 0.3s ease-out;
  backface-visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  pointer-events: none; }
  .loader__visible {
    opacity: 1;
    pointer-events: auto; }
